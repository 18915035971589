<template>
    <!--PUT HTML HERE-->
    <div>
        <kr-search
            ref="teachers_listing"
            name="teachers_listing"
            :url="{ url: search_url }"
            :columns="columns"
            :options="options"
            class="overflowXinherit"
            @retrieve-data="getSearchData"
        >
            <template #ndata="props">
                <tr>
                    <td :colspan="props.model.total_column_count" class="borderNone">No teachers match your search / filter</td>
                </tr>
            </template>

            <template #upper-right>
                <div class="viewFilter">
                    <div class="form-group displayContent">
                        <div class="form-dropdown viewFilter marginLeft0">
                            <select
                                v-model="selectedOption"
                                class="form-control"
                                @change="
                                    $refs['teachers_listing'].debounced_search(true);
                                    changeValue();
                                "
                            >
                                <option value="">Firstname Lastname</option>
                                <option value="Lastname, Firstname">Lastname, Firstname</option>
                            </select>
                        </div>
                    </div>
                </div>
                <router-link v-if="canAddTeacher" v-slot="{ navigate }" :to="{ name: 'teachers.create' }" custom>
                    <button class="btn btn-success" :disabled="auth.isImpersonation() ? true : undefined" @click="navigate">
                        <i class="fa fa-plus marginRight8" aria-hidden="true"></i>New Teacher
                    </button>
                </router-link>
                <button
                    v-if="canAddTeacher"
                    class="btn btn-primary"
                    aria-labelledby="Import Teacher"
                    :disabled="auth.isImpersonation() ? true : undefined"
                    aria-label="Import Teacher"
                    @click="openUploadTeachersModal"
                >
                    <i class="fa fa-file-import marginRight8" aria-hidden="true"></i>Import
                </button>

                <button class="btn btn-primary" aria-label="Export" @click="downloadTeachers">
                    <i class="fa fa-download marginRight8" aria-hidden="true"></i>Export
                </button>
            </template>
            <template #lower-left-right>
                <router-link v-if="canAddTeacher" v-slot="{ navigate }" :to="{ name: 'teachers.create' }" custom>
                    <button class="btn btn-success hidden-md hidden-lg" :disabled="auth.isImpersonation() ? true : undefined" @click="navigate">
                        <i class="fa fa-plus marginRight8" aria-hidden="true"></i>Teacher
                    </button>
                </router-link>

                <button
                    v-if="canAddTeacher"
                    class="btn btn-primary"
                    aria-labelledby="Import Teacher"
                    :disabled="auth.isImpersonation() ? true : undefined"
                    aria-label="Import Teacher"
                    @click="openUploadTeachersModal"
                >
                    <i class="fa fa-file-import marginRight8" aria-hidden="true"></i>Import
                </button>

                <button class="btn btn-primary" aria-label="Export" @click="downloadTeachers">
                    <i class="fa fa-download marginRight8" aria-hidden="true"></i>Export
                </button>
            </template>

            <template #table-title="props">
                <th colspan="6">
                    <template v-if="props.model.meta.paginator.total > 0">
                        Showing {{ props.model.from }} - {{ props.model.to }} of
                        {{ props.model.meta.paginator.total }}
                    </template>
                </th>
            </template>
            <template #bottom-display-from-to="props">
                <div v-if="props.model.meta.paginator.total > 0" class="displayNo fontBold">
                    Showing {{ props.model.from }} - {{ props.model.to }} of
                    {{ props.model.meta.paginator.total }}
                </div>
            </template>

            <template #cdata="props">
                <td>
                    <template v-if="selectedOption == 'Lastname, Firstname'">
                        <router-link
                            v-if="props.model.uuid != auth.user().uuid"
                            v-tooltip="{ content: 'View Teacher' }"
                            tag="a"
                            :to="{
                                name: 'teachers.view',
                                params: { id: props.model.uuid },
                            }"
                            class="link"
                            :aria-label="
                                'click to View teacher ' + (props.model.lastname ? props.model.lastname + ', ' : '') + props.model.firstname + ' profile'
                            "
                            v-dompurify-html="highlight((props.model.lastname ? props.model.lastname + ', ' : '') + props.model.firstname)"
                        >
                        </router-link>
                        <router-link
                            v-else
                            v-tooltip="{ content: 'View Teacher' }"
                            tag="a"
                            :to="{
                                name: 'profile.view',
                                params: { id: props.model.uuid },
                            }"
                            class="link"
                            :aria-label="
                                'click to View teacher' + (props.model.lastname ? props.model.lastname + ', ' : '') + props.model.firstname + ' profile'
                            "
                            v-dompurify-html="highlight((props.model.lastname ? props.model.lastname + ', ' : '') + props.model.firstname)"
                        >
                        </router-link>
                    </template>
                    <template v-else>
                        <router-link
                            v-if="props.model.uuid != auth.user().uuid"
                            v-tooltip="{ content: 'View Teacher' }"
                            tag="a"
                            :to="{
                                name: 'teachers.view',
                                params: { id: props.model.uuid },
                            }"
                            class="link"
                            :aria-label="
                                'click to View teacher ' + props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '') + ' profile'
                            "
                            v-dompurify-html="highlight(props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : ''))"
                        >
                        </router-link>
                        <router-link
                            v-else
                            v-tooltip="{ content: 'View Teacher' }"
                            tag="a"
                            :to="{
                                name: 'profile.view',
                                params: { id: props.model.uuid },
                            }"
                            class="link"
                            :aria-label="
                                'click to View teacher' + props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '') + ' profile'
                            "
                            v-dompurify-html="highlight(props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : ''))"
                        >
                        </router-link>
                    </template>
                </td>
                <td class="hidden-sm hidden-xs" :aria-label="'teacher role ' + props.model.role">
                    <template v-if="props.model.role == 'Super Admin'"> Super Admin </template>
                    <template v-else-if="props.model.canAddTeacher"> Admin Teacher </template>
                    <template v-else> Teacher </template>
                </td>
                <td
                    class="hidden-sm hidden-xs"
                    :arial-label="`teacher email ${props.model.email ? props.model.email : 'none'}`"
                    v-dompurify-html="highlight(props.model.email ? props.model.email : '-')"
                ></td>
                <td v-if="auth.user().roleId == 2" class="hidden-xs hidden-sm" :aria-label="`${searchData.query.isSuspended == '0' ? 'Active' : 'Suspend'}`">
                    <template v-if="searchData.query.isSuspended == '0'">
                        <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true"></i>Active
                    </template>
                    <template v-else> <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true"></i>Suspended </template>
                </td>
                <td class="py-9px">
                    <div v-if="props.model.uuid != auth.user().uuid" class="dropdown">
                        <button
                            id="viewDropdownMenuButton"
                            class="btn"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            @click="dropdownToggle"
                        >
                            Actions<i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                            <ul>
                                <li>
                                    <router-link
                                        class="primary-state"
                                        :to="{
                                            name: 'teachers.view',
                                            params: { id: props.model.uuid },
                                        }"
                                        :aria-labelledby="
                                            'View ' + props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '') + ' profile'
                                        "
                                    >
                                        <span> <i class="fas fa-eye marginRight8" aria-hidden="true"></i>View Teacher </span>
                                    </router-link>
                                </li>
                                <li
                                    v-if="
                                        canAddTeacher &&
                                        searchData.query.isSuspended == '0' &&
                                        ((auth.user().roleId == 3 && props.model.roleId != 2) || auth.user().roleId == 2)
                                    "
                                >
                                    <a
                                        class="primary-state"
                                        href="#"
                                        :aria-labelledby="
                                            'Reset ' + props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '') + ' password'
                                        "
                                        @click.prevent="openResetPasswordModal(props.model)"
                                    >
                                        <span> <i class="fa fa-key marginRight8" aria-hidden="true"></i>Reset Password </span>
                                    </a>
                                </li>

                                <template v-if="auth.user().roleId < 3 || (auth.user().canAddTeacher && props.model.roleId == 3)">
                                    <hr />

                                    <li v-if="searchData.query.isSuspended == '0'">
                                        <a class="danger-state" href="#" @click.prevent="openSuspendModal(props.model)">
                                            <span> <i class="fa fa-archive marginRight8" aria-hidden="true"></i>Suspend </span>
                                        </a>
                                    </li>

                                    <li v-else>
                                        <a class="primary-state" href="#" @click.prevent="openSuspendModal(props.model)">
                                            <span> <i class="fa fa-refresh marginRight8" aria-hidden="true"></i>Reinstate </span>
                                        </a>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </td>
            </template>
        </kr-search>

        <div
            id="suspendModal"
            class="modal"
            :class="{ 'danger-modal': searchData.query.isSuspended == '0', 'warning-modal': searchData.query.isSuspended != '0' }"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="suspendModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="suspendModal-title" class="modal-title">
                            <template v-if="searchData.query.isSuspended == '0'"> Suspend Teacher </template>
                            <template v-else> Reinstate Teacher </template>
                        </h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                        <h2 class="marginTop40">
                            <template v-if="searchData.query.isSuspended == '0'"> Suspend </template>
                            <template v-else> Reinstate </template>
                        </h2>

                        <h3 class="marginBottom20 fw-normal capitalize">
                            {{ user.displayName }}
                        </h3>

                        <template v-if="searchData.query.isSuspended == '0'">
                            <p class="marginTop20">Suspending this teacher will prevent them</p>

                            <p>from sign in to the system.</p>

                            <p class="marginTop20"><b>Note!</b> they will not receive any</p>

                            <p>notification regarding this action.</p>

                            <p class="marginTop20">
                                <b>Are you sure to suspend the teacher?</b>
                            </p>
                        </template>

                        <template v-else>
                            <p class="marginTop20">Reinstating this teacher will restore their</p>

                            <p>ability to sign in to the system.</p>

                            <p class="marginTop20"><b>Note!</b> they will not receive any</p>

                            <p>notification regarding this action.</p>

                            <p class="marginTop20">
                                <b>Are you sure to reinstate the teacher?</b>
                            </p>
                        </template>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                        <button v-if="searchData.query.isSuspended == '0'" class="btn btn-danger" data-dismiss="modal" @click.prevent="suspend()">
                            <i class="fa fa-archive marginRight8" aria-hidden="true" />Yes, Suspend
                        </button>
                        <button v-else class="btn btn-success" data-dismiss="modal" @click.prevent="unsuspend()">Yes, Reinstate</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="uploadTeachersModal" class="modal default-modal uploadTeachersModal" tabindex="-1" role="dialog" aria-labelledby="uploadTeachersModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="uploadTeacherssModal-title" class="modal-title">File Upload</h2>
                    </div>
                    <div class="modal-body">
                        <div class="flex align-items marginBottom20">
                            <p class="marginRight8">
                                All teacher details are
                                <span class="important">compulsory</span>
                            </p>

                            <button
                                class="btn btn-primary margin0 flexInherit"
                                aria-label="Download CSV Template"
                                @click.prevent="download('intedashboard-teacher-upload-template.csv')"
                            >
                                <i class="fa fa-download marginRight8" aria-hidden="true"></i>
                                CSV Template
                            </button>
                        </div>
                        <div class="creation">
                            <div class="uploadDiv">
                                <div>
                                    <div>
                                        <div>
                                            <i class="fas fa-file-upload" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <button class="btn btn-primary" @click.prevent="openBulkUpload">Upload Here</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="uploadErrorTeachersModal"
            class="modal default-modal uploadErrorTeachersModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="uploadErrorTeachersModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="uploadErrorTeachersModal-title" class="modal-title">File Upload Errors</h2>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-danger marginTop10 marginBottom10" role="alert">
                            <i class="fa fa-info-cross" aria-hidden="true"></i>
                            <!--You must upload a .CSV file (comma-separated-values file).-->
                            <span v-dompurify-html="upload_form.errors.get('data')"></span>
                        </div>

                        <div class="uploadDiv">
                            <div>
                                <div>
                                    <div>
                                        <i class="fas fa-file-upload" aria-hidden="true"></i>
                                    </div>
                                    <!-- <div>Drag and drop files here to upload or</div> -->
                                </div>
                                <button class="btn btn-primary" @click.prevent="openBulkUpload">Upload Here</button>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- <kr-reset-password></kr-reset-password> -->
    </div>
</template>

<script>
import KrAuth from '../../../../../components/auth/auth';
import KrForm from '../../../../../components/forms/form';
import * as filestack from 'filestack-js';
import { useDownload } from '@/composables/helpers/useDownload.ts';
export default {
    data() {
        let { download } = useDownload();
        var auth = new KrAuth();
        return {
            download: download,
            component_done_loading: false,
            auth: auth,
            columns: {},
            options: {},
            trash_field: true,
            searchData: {
                meta: { paginator: { total: 0 }, filters: {} },
                query: { isSuspended: '0' },
                q: '',
            },
            search_url: '/teachers',
            user: {},
            selectedOption: '',
            upload_form: new KrForm({
                fields: {
                    data: '',
                    //topic:'',
                },
                props: {},
                urls: {
                    post: 'questions/store-uploaded',
                },
            }),
        };
    },
    computed: {
        canAddTeacher() {
            if (this.auth.user().roleId == 2 || this.auth.user().canAddTeacher) {
                return true;
            }
            return false;
        },
        canSend() {
            if (this.upload_form.model.data) {
                return true;
            }
            return false;
        },
    },
    created() {
        this.init();
        this.component_done_loading = true;
    },
    methods: {
        getSearchData(data) {
            this.searchData = data;
        },
        openResetPasswordModal(user) {
            Events.fire('open-reset-password-modal', {
                user: user,
                organisation: this.auth.user().organisationName,
            });
        },
        init() {
            if (this.auth.user().roleId == 2) {
                this.columns = {
                    firstName: 'display:First Name|sortable:true|width:auto|subDisplay:Last Name|subSortable:true|subSrc:lastName',
                    role: 'display:Role|sortable:false|mobile:hide|width:160px',
                    email: 'display:Email|sortable:true|mobile:hide|width:30%',
                    status: 'display:Status|sortable:true|mobile:hide|width:140px',
                    action: 'display:Actions|sortable:false|width:132px',
                };
            } else {
                this.columns = {
                    firstName: 'display:First Name|sortable:true|width:34.5%|subDisplay:Last Name|subSortable:true|subSrc:lastName',
                    role: 'display:Role|sortable:false|mobile:hide|width:160px',
                    email: 'display:Email|sortable:true|mobile:hide|width:34.5%',
                    action: 'display:Actions|sortable:false|width:132px',
                };
                this.trash_field = false;
            }

            this.options = {
                suspended_field: this.trash_field,
                params: { role: '2,3' },
                action_column: false,
                pageName: 'Teachers',
            };
        },
        async openSuspendModal(user) {
            this.user = user;
            $('#suspendModal').modal('show');
        },
        suspend() {
            $('#suspendModal').modal('hide');
            var success = function (response) {
                this.user.isSuspended = true;

                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Account has been suspended',
                });
                Events.fire('teachers_listing_refresh');
            }.bind(this);
            var del = function () {
                this.user.isSuspended = false;

                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);

            axios
                .post('/users/' + this.user.uuid + '/suspend', {})
                .then(function (response) {
                    success(response);
                })
                .catch(function (errors) {
                    del();
                });
        },
        unsuspend() {
            $('#suspendModal').modal('hide');
            var success = function (response) {
                this.user.isSuspended = false;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Account has been reinstated',
                });
                Events.fire('teachers_listing_refresh');
            }.bind(this);
            var del = function () {
                this.user.isSuspended = true;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .post('/users/' + this.user.uuid + '/reinstate', {})
                .then(function (response) {
                    success(response);
                })
                .catch(function (errors) {
                    del();
                });
        },
        highlight(text) {
            if (this.searchData.query.q != '') {
                if (text != null) {
                    return text.replace(
                        new RegExp(this.searchData.query.q.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'),
                        '<span class="table-keyword-highlight">$&</span>'
                    );
                }
            } else {
                return text;
            }
        },
        dropdownToggle() {
            if ($('table tbody tr').length >= 10) {
                $('table tbody tr:nth-last-child(-n+4) td:last-child div').addClass('dropup');
            } else if ($('table tbody tr').length >= 5) {
                $('table tbody tr:nth-last-child(-n+2) td:last-child div').addClass('dropup');
            }
        },
        changeValue() {
            if (this.auth.user().roleId == 2) {
                if (this.selectedOption == '') {
                    const propertyPosition = Object.keys(this.columns).indexOf('lastName');
                    this.columns.firstName = this.columns.lastName;
                    //ue.set(this.columns, 'firstName', this.columns.lastName);
                    delete this.columns.lastName;
                    this.columns = {
                        ...Object.keys(this.columns)
                            .slice(0, propertyPosition)
                            .reduce((obj, key) => ((obj[key] = this.columns[key]), obj), {}),
                        firstName: Object.values(this.columns)[propertyPosition],
                        ...Object.keys(this.columns)
                            .slice(this.columns.length)
                            .reduce((obj, key) => ((obj[key] = this.columns[key]), obj), {}),
                    };
                    this.columns.firstName = 'display:First Name|sortable:true|width:auto|subDisplay:Last Name|subSortable:true|subSrc:lastName';
                } else {
                    const propertyPosition = Object.keys(this.columns).indexOf('firstName');
                    this.columns.lastName = this.columns.firstName;
                    //Vue.set(this.columns, 'lastName', this.columns.firstName);
                    delete this.columns.firstName;
                    this.columns = {
                        ...Object.keys(this.columns)
                            .slice(0, propertyPosition)
                            .reduce((obj, key) => ((obj[key] = this.columns[key]), obj), {}),
                        lastName: Object.values(this.columns)[propertyPosition],
                        ...Object.keys(this.columns)
                            .slice(this.columns.length)
                            .reduce((obj, key) => ((obj[key] = this.columns[key]), obj), {}),
                    };
                    this.columns.lastName = 'display:Last Name|sortable:true|width:auto|subDisplay:First Name|subSortable:true|subSrc:firstName';
                }
            } else {
                if (this.selectedOption == '') {
                    const propertyPosition = Object.keys(this.columns).indexOf('lastName');
                    this.columns.firstName = this.columns.lastName;
                    //Vue.set(this.columns, 'firstName', this.columns.lastName);
                    delete this.columns.lastName;
                    this.columns = {
                        ...Object.keys(this.columns)
                            .slice(0, propertyPosition)
                            .reduce((obj, key) => ((obj[key] = this.columns[key]), obj), {}),
                        firstName: Object.values(this.columns)[propertyPosition],
                        ...Object.keys(this.columns)
                            .slice(this.columns.length)
                            .reduce((obj, key) => ((obj[key] = this.columns[key]), obj), {}),
                    };
                    this.columns.firstName = 'display:FIRST NAME|sortable:true|width:34.5%|subDisplay:LAST NAME|subSortable:true|subSrc:lastname';
                    /*Vue.set(
                        this.columns,
                        'firstName',
                        'display:FIRST NAME|sortable:true|width:34.5%|subDisplay:LAST NAME|subSortable:true|subSrc:lastname'
                    );*/
                } else {
                    const propertyPosition = Object.keys(this.columns).indexOf('firstName');
                    this.columns.lastName = this.columns.firstName;
                    //Vue.set(this.columns, 'lastName', this.columns.firstName);
                    delete this.columns.firstName;
                    this.columns = {
                        ...Object.keys(this.columns)
                            .slice(0, propertyPosition)
                            .reduce((obj, key) => ((obj[key] = this.columns[key]), obj), {}),
                        lastName: Object.values(this.columns)[propertyPosition],
                        ...Object.keys(this.columns)
                            .slice(this.columns.length)
                            .reduce((obj, key) => ((obj[key] = this.columns[key]), obj), {}),
                    };
                    this.columns.lastName = 'display:LAST NAME|sortable:true|width:34.5%|subDisplay:FIRST NAME|subSortable:true|subSrc:firstName';
                    /*Vue.set(
                        this.columns,
                        'lastName',
                        'display:LAST NAME|sortable:true|width:34.5%|subDisplay:FIRST NAME|subSortable:true|subSrc:firstName'
                    );*/
                }
            }
        },
        openUploadTeachersModal() {
            $('#uploadTeachersModal').modal('show');
        },
        openBulkUpload() {
            var client = filestack.init(process.env.VUE_APP_FILESTACK_API_KEY);
            var that = this;
            client
                .picker({
                    accept: ['.csv'],
                    maxFiles: 1,
                    minFiles: 1,
                    uploadInBackground: false,
                    onFileUploadFinished: function (file) {
                        $('#loading').removeClass('hide').addClass('show');
                        that.upload_form.model.data = file;
                        let url = 'teachers/store-uploaded';
                        that.$papa.parse(file.url, {
                            download: true,
                            complete: function (results, file) {
                                if (results.errors.length == 0) {
                                    that.upload_form.post(url).then(function (response) {
                                        //console.log(response);
                                        if (!response.krFormError) {
                                            that.$refs['teachers_listing'].debounced_search(true);
                                        } else {
                                            if (!that.upload_form.errors.has('data')) {
                                                that.upload_form.errors.append('data', ['Malformed CSV. Please recheck.']);
                                            }
                                            $('#uploadErrorTeachersModal').modal('show');
                                        }
                                    });
                                } else {
                                    that.upload_form.errors.append('data', ['Malformed CSV. Please recheck.']);
                                    $('#uploadErrorTeachersModal').modal('show');
                                }
                                $('#loading').removeClass('show').addClass('hide');
                                $('#uploadTeachersModal').modal('hide');
                            },
                        });
                    },
                })
                .open();
        },
        downloadTeachers() {
            let d = moment().format('YYYY[_]MM[_]DD');
            axios({
                url: `teachers/export`,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Teachers_' + d + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
    },
};
</script>
