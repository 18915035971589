<template>
    <kr-panel :with-footer="false" class="overflowXinherit">
        <template #title>
            <h2>{{ $t('modules.accounts.students.listing.headingLabel') }}</h2>
        </template>
        <template #content>
            <kr-search
                ref="students_listing"
                name="students_listing"
                :url="{ url: `students` }"
                :columns="{
                    displayName: 'display:' + $t('modules.accounts.students.listing.fullnameLabel') + '|sortable:true|width:50%',
                    organisationID: 'display:' + $t('modules.accounts.students.listing.studentIdLabel') + '|sortable:true|width:50%|mobile:hide',
                    status:
                        'display:' +
                        $t('modules.accounts.students.listing.statusLabel') +
                        '|sortable:true|width:180px|minWidth:180px|maxWidth:180px|mobile_width:180px|mobile_minWidth:180px|mobile_maxWidth:180px|mobile:hide',
                    origin:
                        'display:' +
                        $t('modules.accounts.students.listing.originLabel') +
                        '|sortable:true|width:150px|minWidth:150px|maxWidth:150px|mobile_width:150px|mobile_minWidth:150px|mobile_maxWidth:150px|mobile:hide',
                    action: 'display:Actions|sortable:false|width:142px|minWidth:142px|maxWidth:142px|mobile_width:142px|mobile_minWidth:142px|mobile_maxWidth:142px',
                }"
                :options="{
                    per_page: 10,
                    query_fields: student_query_fields,
                    default_query_fields: {
                        sort: 'dateCreated',
                        order: 'desc',
                    },
                    search_history_id: 'accounts_students_index',
                    action_column: false,
                    newServerMode: {
                        includes: ['q', 'isSuspended', 'sort', 'order', 'origin', 'courseUuid', 'status', 'enrolled'],
                    },
                    table_caption: true,
                }"
                @retrieve-data="getStudentSearchData"
            >
                <template #lower-left>
                    <div class="viewFilter">
                        <div class="form-group displayContent">
                            <div class="form-dropdown">
                                <select
                                    v-model="student_query_fields.status"
                                    class="form-control"
                                    :aria-label="'Show ' + student_query_fields.status + ' status'"
                                    @change="$refs['students_listing'].debounced_search(true)"
                                >
                                    <option value="">All Status</option>
                                    <option value="Invite Sent">Invite Sent</option>
                                    <option value="Not Yet Invited">Not Yet Invited</option>
                                    <option v-if="![3].includes(paidSettings)" value="Active">Active</option>
                                    <option v-if="[1, 3].includes(paidSettings)" value="Active (Not Yet Paid)">Active (Not Yet Paid)</option>
                                    <option v-if="[1, 3].includes(paidSettings)" value="Active (Paid)">Active (Paid)</option>
                                    <option v-if="[1, 3].includes(paidSettings)" value="Lapsed">Lapsed</option>
                                    <option v-if="[1, 3].includes(paidSettings)" value="Expired">Expired</option>
                                    <option value="Suspended">Suspended</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="viewFilter">
                        <div class="dropdown new-custom-search-select">
                            <button
                                id="viewDropdownMenuButton"
                                class="btn"
                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                aria-label="Search Course"
                            >
                                <template v-if="course === 'all'"> All Enrollments </template>
                                <template v-else-if="course === 'enrolled'"> Enrolled in any course </template>
                                <template v-else-if="course === 'not enrolled'"> Not enrolled in any course </template>
                                <template v-else>
                                    {{ course.code ? course.code + ' - ' + course.name : course.name }}
                                </template>

                                <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewDropdownMenuButton">
                                <div class="form-group form-search has-feedback has-feedback-left">
                                    <input
                                        v-model="studentSearchData.q"
                                        type="text"
                                        class="form-control"
                                        placeholder="Search..."
                                        @keyup="searchCourseDropdown"
                                    />
                                    <i class="fa fa-search form-control-feedback" aria-hidden="true"></i>
                                </div>

                                <ul>
                                    <div
                                        v-if="studentSearchData.q && (resultQuery.length / perPage).toFixed() >= 2 && resultQuery.length != 0"
                                        class="padding10 paddingTop0 flexSpaceBetween align-items"
                                    >
                                        <a
                                            class="link noBg flexOnly align-items lineHeight1"
                                            href="#"
                                            :class="{
                                                disabled: pageNumber == 0,
                                            }"
                                            @click="previous"
                                        >
                                            <i class="fa fa-angle-double-left marginRight8" aria-hidden="true"></i>Prev
                                        </a>

                                        <span class="lineHeight1">
                                            Page
                                            {{ pageNumber + 1 }} of
                                            {{ (resultQuery.length / perPage).toFixed() }}
                                        </span>

                                        <a
                                            class="link noBg flexOnly align-items lineHeight1"
                                            href="#"
                                            :class="{
                                                disabled: pageNumber >= (resultQuery.length / perPage).toFixed() - 1,
                                            }"
                                            @click="next"
                                        >
                                            Next<i class="fa fa-angle-double-right marginLeft8" aria-hidden="true"></i>
                                        </a>
                                    </div>

                                    <template v-if="studentSearchData.isSearching">
                                        <template v-for="course in resultQuery.slice(pageNumber * perPage, pageNumber * perPage + perPage)" :key="course.uuid">
                                            <li
                                                @click="
                                                    $refs['students_listing'].debounced_search(true);
                                                    select(course);
                                                "
                                                v-dompurify-html="courseHighlight(course.code ? course.code + ' - ' + course.name : course.name)"
                                            ></li>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <li
                                            key="allCourse"
                                            @click="
                                                select('all');
                                                $refs['students_listing'].debounced_search(true);
                                            "
                                        >
                                            All Enrollments
                                        </li>
                                        <li
                                            key="enrolledCourse"
                                            @click="
                                                select('enrolled');
                                                $refs['students_listing'].debounced_search(true);
                                            "
                                        >
                                            Enrolled in any course
                                        </li>
                                        <li
                                            key="notEnrolledCourse"
                                            @click="
                                                select('not enrolled');
                                                $refs['students_listing'].debounced_search(true);
                                            "
                                        >
                                            Not enrolled in any course
                                        </li>

                                        <label v-if="coursesList.length > 0" class="dropdownHeading">
                                            Course (Showing 8 of
                                            {{ coursesList.length }})
                                        </label>
                                        <template v-if="coursesList.length > 0">
                                            <template v-for="course in coursesList.slice(0, 8)" :key="course.uuid">
                                                <li
                                                    @click="
                                                        select(course);
                                                        $refs['students_listing'].debounced_search(true);
                                                    "
                                                >
                                                    {{ course.code ? course.code + ' - ' + course.name : course.name }}
                                                </li>
                                            </template>
                                        </template>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="viewFilter">
                        <div class="form-group displayContent">
                            <div class="form-dropdown viewFilter marginLeft0">
                                <select
                                    v-model="student_query_fields.origin"
                                    class="form-control"
                                    :aria-label="
                                        student_query_fields.origin == ''
                                            ? 'Show ' + student_query_fields.origin
                                            : 'Show ' + student_query_fields.origin + ' origin'
                                    "
                                    @change="$refs['students_listing'].debounced_search(true)"
                                >
                                    <option value="">All Origins</option>
                                    <option value="Express Sign-in">Express Sign-in</option>
                                    <option value="LTI">LTI</option>
                                    <option value="Email">Email</option>
                                    <option value="Generic">Generic</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </template>

                <template #upper-right>
                    <button class="btn btn-primary" @click="downloadStudents">
                        <i class="fa fa-download marginRight8" aria-hidden="true" />{{ $t('modules.accounts.students.listing.exportButtonLabel') }}
                    </button>
                </template>

                <template #table-title="props">
                    <th colspan="8">
                        {{ $t('modules.accounts.students.listing.headingLabel') }}

                        <template v-if="props.model.meta.paginator.total > 0">
                            ( {{ props.model.from }} - {{ props.model.to }} of {{ props.model.meta.paginator.total }} )
                        </template>
                    </th>
                </template>

                <template #bottom-display-from-to="props">
                    <div v-if="props.model.meta.paginator.total > 0" class="displayNo">
                        {{ props.model.from }} - {{ props.model.to }} of
                        {{ props.model.meta.paginator.total }}
                    </div>
                </template>

                <template #table-caption> List of Students in {{ account.organisationName }} </template>

                <template #cdata="props">
                    <td>
                        {{ props.model.displayName }}
                        <template v-if="props.model.hasTeacherAccount">
                            <span class="fontGrey"> (Also a teacher) </span>
                        </template>
                        <br />
                        <span class="fontGrey fs-16px">
                            {{ props.model.email ? props.model.email : '-' }}
                        </span>
                    </td>

                    <td class="hidden-xs hidden-sm">
                        {{ props.model.organisationID ? props.model.organisationID : '' }}
                    </td>

                    <td class="hidden-xs hidden-sm">
                        <i
                            class="fa fa-circle marginRight8"
                            :class="{
                                suspendCircle: ['Lapsed', 'Expired', 'Suspended'].includes(props.model.status),
                                readOnlyCircle: ['Invite Sent', 'Not Yet Invited'].includes(props.model.status),
                                activeCircle: ['Active', 'Active (Not Yet Paid)', 'Active (Paid)'].includes(props.model.status),
                            }"
                            aria-hidden="true"
                        />
                        {{ props.model.status }}
                    </td>

                    <td class="hidden-xs hidden-sm">
                        {{ props.model.origin }}
                    </td>

                    <td>
                        <div class="dropdown">
                            <button id="viewDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="Actions">
                                {{ $t('modules.accounts.students.listing.actionsButtonLabel') }}<i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                            </button>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                <ul>
                                    <li>
                                        <router-link
                                            v-tooltip="'View Student'"
                                            class="primary-state"
                                            :to="{
                                                name: 'accounts.students.view',
                                                params: {
                                                    id: props.model.uuid,
                                                },
                                            }"
                                            aria-label="View Student"
                                        >
                                            <span>
                                                <i class="fas fa-eye marginRight8" aria-hidden="true" />{{
                                                    $t('modules.accounts.students.listing.viewButtonLabel')
                                                }}</span
                                            >
                                        </router-link>
                                    </li>

                                    <li v-if="[1, 2, 3].includes(auth.user().roleId) && props.model.email">
                                        <a
                                            class="primary-state"
                                            href="#"
                                            aria-label="Reset Password"
                                            @click.prevent="emit('emitResetPassword', props.model, 'student')"
                                        >
                                            <span>
                                                <i class="fa fa-key marginRight8" aria-hidden="true" />{{
                                                    $t('modules.accounts.students.listing.resetPasswordButtonLabel')
                                                }}
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </template>
            </kr-search>
        </template>
    </kr-panel>
</template>
<script setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useAuthUserStore } from '../../../../stores/auth';
import KrAuth from '../../../../components/auth/auth';

const emit = defineEmits(['emitResetPassword']);

const route = useRoute();
const { t } = useI18n();
const auth = new KrAuth();

const props = defineProps({
    account: {
        type: Object,
        required: true,
    },
    coursesList: {
        type: Array,
        required: true,
    },
    paidSettings: {
        type: Number,
        required: true,
    },
});

const store = useAuthUserStore();
let history = store.search_history;
let search_history_id = 'accounts_students_index';

const studentSearchData = ref({
    meta: { paginator: { total: 0 }, filters: {} },
    query: {},
});

const student_query_fields = ref({
    status: _.get(history, `${search_history_id}.status`, ''),
    origin: _.get(history, `${search_history_id}.origin`, ''),
    courseUuid: _.get(history, `${search_history_id}.courseUuid`, ''),
    enrolled: _.get(history, `${search_history_id}.enrolled`, ''),
    accountUuid: route.params.id,
});

const resultQuery = ref([]);
const pageNumber = ref(0);
const perPage = ref(10);
const course = ref('all');

const getStudentSearchData = (data) => {
    studentSearchData.value = data;
};

const searchCourseDropdown = () => {
    resultQuery.value = [];
    pageNumber.value = 0;

    if (studentSearchData.value.q.match(/[a-z]/i) != null) {
        studentSearchData.value.isSearching = true;
        studentSearchData.value.matched = 0;
        for (var i = 0; i < props.coursesList.length; i++) {
            //Vue.set(this.coursesList[i], '_matched', 0);
            props.coursesList[i]._matched = 0;
            let course = props.coursesList[i];
            let haystack = (course.name + course.code + '').toLowerCase();
            let doesContain = haystack.indexOf(studentSearchData.value.q.toLowerCase());
            if (doesContain != -1) {
                couse._searchDisplay = true;
                //Vue.set(course, '_searchDisplay', true);
                studentSearchData.value.matched++;
                props.coursesList[i]._matched++;

                resultQuery.value.push(props.coursesList[i]);
            } else {
                //Vue.set(course, '_searchDisplay', false);
                couse._searchDisplay = false;
            }
        }
    } else {
        studentSearchData.value.isSearching = false;
    }
};

const select = (option) => {
    course.value = false;
    student_query_fields.value.courseUuid = '';
    if (option === 'all') {
        course.value = 'all';
        student_query_fields.value.enrolled = '';
    } else if (option === 'enrolled') {
        course.value = 'enrolled';
        student_query_fields.value.enrolled = 1;
    } else if (option === 'not enrolled') {
        course.value = 'not enrolled';
        student_query_fields.value.enrolled = 0;
    } else {
        student_query_fields.value.courseUuid = option.uuid;
        if (student_query_fields.value.courseUuid != '') {
            course.value = _.find(props.coursesList, function (o) {
                return o.uuid == student_query_fields.value.courseUuid;
            });
        }
    }
};

const next = (event) => {
    pageNumber.value++;
    event.stopPropagation();
};

const previous = (event) => {
    pageNumber.value--;
    event.stopPropagation();
};

const courseHighlight = (text) => {
    if (studentSearchData.value.q != '') {
        return text.replace(new RegExp(studentSearchData.value.q, 'gi'), '<span class="table-keyword-highlight">$&</span>');
    } else {
        return text;
    }
};

const downloadStudents = () => {
    let d = moment().format('YYYY[_]MM[_]DD');

    axios({
        url: `students?filter[q]=${studentSearchData.value.query.q}&accountUuid=${route.params.id}&filter[origin]=${studentSearchData.value.query.origin}&filter[status]=${studentSearchData.value.query.status}&filter[courseUuid]=${studentSearchData.value.query.courseUuid}&filter[enrolled]=${studentSearchData.value.query.enrolled}&export=true`,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Students_' + d + '.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
};
</script>
