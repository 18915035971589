<template>
    <div class="col-xs-12 right-container">
        <h1>Announcements</h1>

        <div class="announcement-card">
            <div class="icon-container">
                <i class="fa-solid fa-graduation-cap" aria-hidden="true" />
            </div>
            <div>
                <div class="header">
                    <h2>Student Scholarship!</h2>
                </div>
                <div class="content">
                    <p>
                        <template v-if="auth.user().role == 'Studnet'">
                            Win up to $1,000! Share how TBL has shaped your learning & career readiness by participating in the InteDashboard Scholarship Essay
                            Competition. Submit by May 30, 2025!
                        </template>
                        <template v-else>
                            Empower Your Students! Encourage them to share their TBL experience in the InteDashboard Scholarship Essay Competition for a chance
                            to win up to $1,000. Submissions close May 30, 2025!
                        </template>
                    </p>
                    <a href="https://www.intedashboard.com/student-scholarship" target="_blank" class="link">Student Scholarship</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import KrAuth from '../../../components/auth/auth';
import { useRoute } from 'vue-router';

const auth = new KrAuth();

const route = useRoute();
const isViewed = ref(false);

onMounted(() => {
    Events.fire('topbar_update', {
        breadcrumb: [{ name: 'Home' }, { name: 'Announcements' }],
    });

    const pageKey = `page_viewed_${route.path}`;
    console.log(pageKey);
    localStorage.setItem(pageKey, 'true');
    isViewed.value = true;
});
</script>

<style scoped lang="scss">
.announcement-card {
    padding: 20px;
    border-radius: 20px;
    max-width: 60%;
    display: flex;
    gap: 16px;
    background: #fff;
    box-shadow: 1px 1px 8px rgba(60, 60, 67, 0.2);

    .icon-container {
        max-width: 64px;
        max-height: 64px;
        border-radius: 10px;
        background-color: #c9eeff;
        color: #222;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        padding: 12px 8px;
    }
}
</style>
