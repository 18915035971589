<template>
    <div
        ref="modal"
        class="modal primary-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="impersonateAccountModal-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="impersonateAccountModal-title" class="modal-title">Impersonate Account</h2>
                </div>
                <div class="modal-body textAlignCenter">
                    <h2>Impersonate</h2>
                    <h3 class="fw-normal">
                        {{ values.displayName }}
                    </h3>

                    <p class="marginTop20">Impersonate user allows you access to the</p>
                    <p>user's account and their courses and more</p>

                    <p class="marginTop20">
                        <b>Are you sure to impersonate the user?</b>
                    </p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                    <button class="btn btn-success" @click.prevent="submit"><i class="fa fa-user marginRight8" />Yes, Impersonate Account</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';
import { notify } from '@kyvg/vue3-notification';
import KrAuth from '../../../../components/auth/auth';

const auth = new KrAuth();
const emit = defineEmits(['emit-reinstate']);

const modal = ref(null);
const values = ref({});

const impersonate = (v = {}) => {
    values.value = v;
    nextTick(() => {
        $(modal.value).modal('show');
    });

    console.log(values.value);
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const submit = () => {
    axios
        .post('auth/impersonate', { userUuid: values.value.uuid })
        .then(function (response) {
            let token = response.data.data.token;

            closeModal();

            axios
                .post('/auth/validate?impersonate', {}, { headers: { Authorization: `Bearer ${token}` } })
                .then(function (response) {
                    auth.login(response.data, {
                        impersonate: true,
                    });
                })
                .catch(function (errors) {
                    notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong, please try again later',
            });
        });
};

defineExpose({ impersonate });
</script>
