<template>
    <kr-panel :with-footer="false">
        <template #title>
            <h2>Access Codes</h2>
        </template>

        <template #content>
            <kr-search
                name="codes_listing"
                :url="{
                    url: '/accounts/' + account.uuid + '/access-codes',
                }"
                :columns="{
                    checkbox: 'display:Select All|sortable:false|width:5%|slotted:true',
                    bookstoreName: 'display:Bookstore|sortable:false|width:8%',
                    accessCode: 'display:Access Code|sortable:false|width:8%|mobile:hide',
                    planName: 'display:Payment Plan|sortable:false|width:8%|mobile:hide',
                    dateCreated: 'display:Date Created|sortable:false|width:280px|minWidth:280px|maxWidth:280px|mobile:hide',
                    validityPeriod: 'display:Validity Period (Weeks)|sortable:false|width:8%|mobile:hide',
                    toBeActivatedBeforeDate: 'display:To Be Used Before|sortable:false|width:280px|minWidth:280px|maxWidth:280px|mobile:hide',
                    validForRefundDate: 'display:Valid For Refund Before|sortable:false|width:280px|minWidth:280px|maxWidth:280px|mobile:hide',
                    status: 'display:Current Status|sortable:false|width:160px|minWidth:160px|maxWidth:160px',
                    dateUsed: 'display:Used On|sortable:false|width:280px|minWidth:280px|maxWidth:280px|mobile:hide',
                    usedBy: 'display:Used By|sortable:false|width:180px|minWidth:180px|maxWidth:180px|mobile:hide',
                    expiryDate: 'display:Account Expiry Date|sortable:false|width:280px|minWidth:280px|maxWidth:280px|mobile:hide',
                    deactivatedOn: 'display:Invalidated On|sortable:false|width:280px|minWidth:280px|maxWidth:280px|mobile:hide',
                    refundedOn: 'display:Refunded On|sortable:false|width:280px|minWidth:280px|maxWidth:280px|mobile:hide',
                }"
                :options="{
                    activated_field: true,
                    trash_field: false,
                    suspended_field: false,
                    action_column: false,
                    search_field: true,
                    query_fields: query_fields,
                }"
                @retrieve-data="getAccessCodeData"
            >
                <template #upper-right>
                    <button class="btn btn-primary margin0" aria-label="Download Access Codes Report" @click="download">
                        <i class="fa fa-download marginRight8" aria-hidden="true" />Access Codes Report
                    </button>

                    <router-link
                        v-slot="{ navigate }"
                        :to="{
                            name: 'accounts.codes.create',
                            params: {
                                account_id: account.uuid,
                            },
                        }"
                        custom
                    >
                        <button class="btn btn-primary" @click="navigate"><i class="fa fa-plus marginRight8" aria-hidden="true" />New Access Codes</button>
                    </router-link>
                </template>

                <template #table-title="props">
                    <div class="flex gap10 align-items">
                        All Access Codes ( {{ props.model.from }} - {{ props.model.to }} of {{ props.model.meta.paginator.total }} )
                        <button v-if="codeFlags.canRefund" class="btn btn-default margin0 flexInherit" @click="openAccessCodeActionModal('refund')">
                            <i class="fas fa-undo marginRight8" aria-hidden="true" />Refund
                        </button>

                        <button v-if="codeFlags.canSuspend" class="btn btn-danger margin0 flexInherit" @click="openAccessCodeActionModal('suspend')">
                            <i class="fas fa-ban marginRight8" aria-hidden="true" />Suspend
                        </button>

                        <button v-if="codeFlags.canDeactivate" class="btn btn-default margin0 flexInherit" @click="openAccessCodeActionModal('deactivate')">
                            <i class="fas fa-user-times marginRight8" aria-hidden="true" />Deactivate
                        </button>

                        <button v-if="codeFlags.canExtend" class="btn btn-default margin0 flexInherit" @click="openAccessCodeActionModal('extend')">
                            <i class="fas fa-clock marginRight8" aria-hidden="true" />Extend Validity
                        </button>

                        <button v-if="codeFlags.canReactivate" class="btn btn-danger margin0 flexInherit" @click="openAccessCodeActionModal('reactivate')">
                            <i class="fas fa-redo-alt marginRight8" aria-hidden="true" />Reactivate
                        </button>
                    </div>
                </template>

                <template #bottom-display-from-to="props">
                    <div class="displayNo">
                        {{ props.model.from }} - {{ props.model.to }} of
                        {{ props.model.meta.paginator.total }}
                    </div>
                </template>

                <template #checkbox>
                    <div class="checkboxField">
                        <input v-model="toggleAccessCodes" type="checkbox" aria-label="Toggle Access Codes" @change="toggleCodes" />
                    </div>
                </template>

                <template #cdata="props">
                    <td>
                        <div v-if="canSelectAccessCode(props.model)" class="checkboxField">
                            <input v-model="codeData" type="checkbox" :value="props.model.uuid" />
                        </div>
                    </td>

                    <td>
                        {{ props.model.bookstoreName }}
                    </td>

                    <td class="hidden-xs hidden-sm">
                        {{ props.model.code }}
                    </td>

                    <td class="hidden-xs hidden-sm">
                        {{ props.model.planName }}
                    </td>

                    <td class="hidden-xs hidden-sm">
                        <p v-if="props.model.dateCreated" v-dompurify-html="parseReadableDate(props.model.dateCreated, true)"></p>
                        <p v-else>-</p>
                    </td>

                    <td class="hidden-xs hidden-sm">
                        <template v-if="props.model.validityPeriod">
                            {{ props.model.validityPeriod }}
                        </template>
                        <template v-else> - </template>
                    </td>

                    <td class="hidden-xs hidden-sm">
                        <p v-if="props.model.toBeActivatedBeforeDate" v-dompurify-html="parseReadableDate(props.model.toBeActivatedBeforeDate, true)"></p>
                        <p v-else>-</p>
                    </td>

                    <td class="hidden-xs hidden-sm">
                        <p v-if="props.model.validForRefundDate" v-dompurify-html="parseReadableDate(props.model.validForRefundDate, true)"></p>
                        <p v-else>-</p>
                    </td>

                    <td>
                        <template v-if="props.model.status == 'unactivated'">
                            <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Unactivated
                        </template>

                        <template v-else-if="props.model.status == 'activated'">
                            <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true" />Activated
                        </template>

                        <template v-else-if="props.model.status == 'suspended'">
                            <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Suspended
                        </template>

                        <template v-else-if="props.model.status == 'deactivated'">
                            <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Deactivated
                        </template>

                        <template v-else-if="props.model.status == 'refunded'">
                            <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Refunded
                        </template>

                        <template v-else-if="props.model.status == 'expired'">
                            <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Expired
                        </template>

                        <template v-else> - </template>
                        <br />
                    </td>

                    <td class="hidden-xs hidden-sm">
                        <p v-if="props.model.dateUsed" v-dompurify-html="parseReadableDate(props.model.dateUsed, true)"></p>
                        <p v-else>-</p>
                    </td>

                    <td class="hidden-xs hidden-sm">
                        <template v-if="props.model.usedByUuid">
                            <router-link
                                v-tooltip="{
                                    content: 'View Student',
                                }"
                                tag="a"
                                :to="{
                                    name: 'accounts.students.view',
                                    params: {
                                        id: props.model.usedByUuid,
                                    },
                                }"
                                class="link"
                                :aria-label="props.model.usedBy"
                            >
                                {{ props.model.usedBy }}
                            </router-link>
                        </template>

                        <template v-else> - </template>
                    </td>

                    <td class="hidden-xs hidden-sm">
                        <p v-if="props.model.expiryDate" v-dompurify-html="parseReadableDate(props.model.expiryDate, true)"></p>
                        <p v-else>-</p>
                    </td>

                    <td class="hidden-xs hidden-sm">
                        <p v-if="props.model.deactivatedOn" v-dompurify-html="parseReadableDate(props.model.deactivatedOn, true)"></p>
                        <p v-else>-</p>
                    </td>

                    <td class="hidden-xs hidden-sm">
                        <p v-if="props.model.refundedOn" v-dompurify-html="parseReadableDate(props.model.refundedOn, true)"></p>
                        <p v-else>-</p>
                    </td>
                </template>

                <template #ndata="props">
                    <td class="textAlignCenter">No access codes</td>
                </template>
            </kr-search>
        </template>
    </kr-panel>

    <refundCodesModal ref="refundModalComponent" @emitCodesListingRefresh="refreshCodes" />
    <extendCodesModal ref="extendModalComponent" @emitCodesListingRefresh="refreshCodes" />
    <suspendCodesModal ref="suspendModalComponent" @emitCodesListingRefresh="refreshCodes" />
    <reactivateCodesModal ref="reactivateModalComponent" @emitCodesListingRefresh="refreshCodes" />
    <deactivateCodesModal ref="deactivateModalComponent" @emitCodesListingRefresh="refreshCodes" />
</template>
<script setup>
import { ref, watch, getCurrentInstance } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import refundCodesModal from '../actions/refundCodesModal.vue';
import extendCodesModal from '../actions/extendCodesModal.vue';
import suspendCodesModal from '../actions/suspendCodesModal.vue';
import reactivateCodesModal from '../actions/reactivateCodesModal.vue';
import deactivateCodesModal from '../actions/deactivateCodesModal.vue';

const { proxy } = getCurrentInstance();
const route = useRoute();
const { t } = useI18n();

const props = defineProps({
    account: {
        type: Object,
        required: true,
    },
});

const codeData = ref([]);
const accessCodes = ref([]);
const cDataFiltered = ref([]);
const codeFlags = ref({
    canRefund: false,
    canSuspend: false,
    canReactivate: false,
    canDeactivate: false,
    canExtend: false,
});
const toggleAccessCodes = ref(false);
const extendMonths = ref(0);

const refundModalComponent = ref(null);
const extendModalComponent = ref(null);
const suspendModalComponent = ref(null);
const reactivateModalComponent = ref(null);
const deactivateModalComponent = ref(null);

const getAccessCodeData = (ret) => {
    accessCodes.value = ret.data.data.data;
};

const getAccessCodeObject = (uuid) => {
    let idx = _.findIndex(accessCodes.value, function (o) {
        return o.uuid == uuid;
    });
    if (idx != -1) {
        return accessCodes.value[idx];
    } else {
        return {};
    }
};

const processFlags = () => {
    let refund = false;
    let suspend = false;
    let reactivate = false;
    let deactivate = false;
    let extend = false;

    for (var i = 0; i < codeData.value.length; i++) {
        let obj = getAccessCodeObject(codeData.value[i]);
        if (['unactivated', 'suspended'].includes(obj.status)) {
            refund = true;
            deactivate = true;
            extend = true;
        }
        if (['unactivated'].includes(obj.status)) {
            suspend = true;
        }
        if (['suspended'].includes(obj.status)) {
            reactivate = true;
        }
        if (['expired'].includes(obj.status)) {
            extend = true;
        }
    }
    codeFlags.value.canRefund = refund;
    codeFlags.value.canSuspend = suspend;
    codeFlags.value.canReactivate = reactivate;
    codeFlags.value.canDeactivate = deactivate;
    codeFlags.value.canExtend = extend;
};

const canSelectAccessCode = (code) => {
    if (['activated', 'deactivated', 'refunded'].includes(code.status)) {
        return false;
    }
    return true;
};

const toggleCodes = () => {
    codeData.value = [];
    if (toggleAccessCodes.value) {
        for (var i = 0; i < accessCodes.value.length; i++) {
            let obj = accessCodes.value[i];
            if (canSelectAccessCode(obj)) {
                codeData.value.push(obj.uuid);
            }
        }
    }
};

const download = () => {
    axios({
        url: `/accounts/${route.params.id}/access-codes/export`,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${props.account.organisationName}_ACCESS_CODES_REPORT_` + moment().format('YYYY_MM_DD') + '.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
};

const openAccessCodeActionModal = (type) => {
    if (type == 'extend') {
        extendMonths.value = 3;
    }
    cDataFiltered.value = [];
    for (var i = 0; i < codeData.value.length; i++) {
        let obj = getAccessCodeObject(codeData.value[i]);
        if (['unactivated', 'suspended'].includes(obj.status)) {
            if (type == 'refund' || type == 'deactivate' || type == 'extend') {
                cDataFiltered.value.push(obj);
            }
        }
        if (['unactivated'].includes(obj.status)) {
            if (type == 'suspend') {
                cDataFiltered.value.push(obj);
            }
        }
        if (['suspended'].includes(obj.status)) {
            if (type == 'reactivate') {
                cDataFiltered.value.push(obj);
            }
        }
        if (['expired'].includes(obj.status)) {
            if (type == 'extend') {
                cDataFiltered.value.push(obj);
            }
        }
    }

    proxy.$refs[`${type}ModalComponent`][type](cDataFiltered.value, type == 'extend' ? extendMonths.value : null);
};

const refreshCodes = () => {
    codeData.value = [];
    codeData.value.splice();
    processFlags();

    Events.fire('codes_listing_refresh');
};

watch(
    codeData,
    (newVal) => {
        if (newVal.length == 0) {
            toggleAccessCodes.value = false;
        }
        if (accessCodes.value.length === codeData.value.length) {
            toggleAccessCodes.value = true;
        }
        processFlags();
    },
    {
        deep: true,
    }
);
</script>
