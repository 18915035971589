<template>
    <kr-panel :with-footer="false">
        <template #title>
            <div class="col-xs-12 col-sm-6">
                <h2>
                    {{ $t('modules.accounts.view.overviewTabLabel') }}
                </h2>
            </div>
            <div class="col-xs-12 col-sm-6" :class="{ textAlignRight: !isBelow768px, marginTop20: isBelow768px }">
                <router-link
                    v-slot="{ navigate }"
                    :to="{
                        name: 'accounts.edit',
                        params: route.params.id,
                    }"
                    custom
                >
                    <button class="btn btn-default" @click="navigate">
                        <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />
                        {{ $t('modules.accounts.view.editButtonLabel') }}
                    </button>
                </router-link>

                <button
                    v-if="!account.isSuspended"
                    id="top-reset-pwd-btn"
                    class="btn btn-default"
                    aria-label="Reset Password"
                    @click.prevent="emit('emitResetPassword', account, 'account')"
                >
                    <i class="fa fa-key marginRight8" aria-hidden="true" />
                    {{ $t('modules.accounts.view.resetPasswordButtonLabel') }}
                </button>

                <router-link v-slot="{ navigate }" :to="{ name: 'accounts.index' }" custom>
                    <button class="btn btn-default" @click="navigate">
                        <i class="fa fa-caret-left marginRight8" aria-hidden="true" />
                        {{ $t('modules.accounts.view.backButtonLabel') }}
                    </button>
                </router-link>
            </div>
        </template>
        <template #content>
            <div class="col-xs-12 col-md-12">
                <div>
                    <div class="form-group">
                        <label class="control-label">{{ $t('modules.accounts.view.organisationNameLabel') }}</label>
                        <p class="form-control-static">
                            {{ account.organisationName }}
                        </p>
                    </div>
                </div>
                <div class="flex">
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                            <label class="control-label">{{ $t('modules.accounts.view.fullnameSuperAdminLabel') }}</label>
                            <p class="form-control-static">
                                {{ account.adminTeacherAccount.displayName }}
                            </p>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                            <label class="control-label">{{ $t('modules.accounts.view.emailLabel') }}</label>
                            <p class="form-control-static">
                                {{ account.adminTeacherAccount.email }}
                            </p>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                            <label class="control-label">{{ $t('modules.accounts.view.jobDesignationLabel') }}</label>
                            <p class="form-control-static">
                                <template v-if="account.adminTeacherAccount.jobDescription">
                                    {{ account.adminTeacherAccount.jobDescription }}
                                </template>
                                <template> - </template>
                            </p>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                            <label class="control-label">{{ $t('modules.accounts.view.influencerLevelLabel') }}</label>
                            <p class="form-control-static">
                                {{ getInfluencerLevelLabel(account.adminTeacherAccount.influencerLevel) }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="flex">
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                            <label class="control-label">{{ $t('modules.accounts.view.segmentLabel') }}</label>
                            <p class="form-control-static">
                                <template v-if="account.adminTeacherAccount.segment">
                                    {{ account.adminTeacherAccount.segment }}
                                </template>
                                <template> - </template>
                            </p>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                            <label class="control-label"> {{ $t('modules.accounts.view.fieldOfDisciplineLabel') }}</label>
                            <p class="form-control-static">
                                <template v-if="account.adminTeacherAccount.fieldOfDiscipline">
                                    {{ account.adminTeacherAccount.fieldOfDiscipline }}
                                    <template v-if="account.adminTeacherAccount.fieldOfDiscipline == 'Others'">
                                        -
                                        {{ account.adminTeacherAccount.otherFieldOfDiscipline }}
                                    </template>
                                </template>
                                <template> - </template>
                            </p>
                        </div>
                    </div>

                    <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                            <label class="control-label">{{ $t('modules.accounts.view.statusLabel') }}</label>
                            <p class="form-control-static">
                                <template v-if="!account.isSuspended"> <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true" />Active </template>
                                <template v-else> <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Suspended </template>
                            </p>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                            <label class="control-label">{{ $t('modules.accounts.view.lmsLabel') }}</label>
                            <p class="form-control-static">
                                <template v-if="account.lms">
                                    {{ account.lms }}
                                </template>
                                <template> - </template>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 flex">
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                            <label class="control-label">{{ $t('modules.accounts.view.typeLabel') }}</label>
                            <p class="form-control-static">
                                {{ account.type }}
                            </p>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                            <label class="control-label"> {{ $t('modules.accounts.view.paymentMethodLabel') }} </label>
                            <p class="form-control-static">
                                {{ account.paymentMethod }}
                            </p>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                            <label class="control-label"> {{ $t('modules.accounts.view.contractStartDateLabel') }} </label>
                            <p class="form-control-static" v-dompurify-html="parseReadableDate(account.startDate)" />
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                            <label class="control-label"> {{ $t('modules.accounts.view.contractEndDateLabel') }} </label>
                            <p class="form-control-static" v-dompurify-html="parseReadableDate(account.expiryDate)" />
                        </div>
                    </div>
                </div>

                <div class="col-xs-12 flex">
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                            <label class="control-label"> {{ $t('modules.accounts.view.createdByLabel') }} </label>
                            <p class="form-control-static">
                                {{ account.createBy }}
                            </p>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                            <label class="control-label"> {{ $t('modules.accounts.view.createdOnLabel') }}</label>
                            <p v-if="account.dateCreated" class="form-control-static" v-dompurify-html="parseReadableDate(account.dateCreated, true)" />
                            <p v-else class="form-control-static">-</p>
                        </div>
                    </div>

                    <template v-if="account.paymentMethod != 'Student-Paid'">
                        <div class="col-xs-12 col-md-3">
                            <div class="form-group">
                                <label class="control-label"> {{ $t('modules.accounts.view.activatedOnLabel') }} </label>
                                <p v-if="account.dateActivated" class="form-control-static" v-dompurify-html="parseReadableDate(account.dateActivated, true)" />
                                <p v-else class="form-control-static">-</p>
                            </div>
                        </div>

                        <div class="col-xs-12 col-md-3">
                            <div class="form-group">
                                <label class="control-label"> {{ $t('modules.accounts.view.expiresOnLabel') }}</label>
                                <p v-if="account.expiryDate" class="form-control-static" v-dompurify-html="parseReadableDate(account.expiryDate)" />
                                <p v-else class="form-control-static">-</p>
                            </div>
                        </div>
                    </template>
                </div>

                <div class="col-xs-12 flex">
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                            <label class="control-label"> {{ $t('modules.accounts.view.accountLastLoginLabel') }}</label>
                            <p
                                v-if="account.adminTeacherAccount.dateLastLogin"
                                class="form-control-static"
                                v-dompurify-html="parseReadableDate(account.adminTeacherAccount.dateLastLogin, true)"
                            />
                            <p v-else class="form-control-static">-</p>
                        </div>
                    </div>

                    <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                            <label class="control-label"> {{ $t('modules.accounts.view.accountLastModifiedLabel') }} </label>
                            <p v-if="account.lastModified" class="form-control-static" v-dompurify-html="parseReadableDate(account.lastModified, true)" />
                            <p v-else class="form-control-static">-</p>
                        </div>
                    </div>

                    <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                            <label class="control-label">{{ $t('modules.accounts.view.versionLabel') }}</label>
                            <p class="form-control-static">
                                <template v-if="account.version == 'full'"> Full </template>
                                <template v-else> Lite </template>
                            </p>
                        </div>
                    </div>
                </div>

                <div v-if="(account.type == 'Paid' || account.type == 'Paid Trial') && account.paymentMethod == 'Student-Paid'" class="col-xs-12 marginTop30">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Plan</th>
                                    <th>Price</th>
                                    <th>Plan Period</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(plan, idx) in account.paymentPlans" :key="idx">
                                    <tr>
                                        <template v-if="plan.plan">
                                            <td>
                                                {{ plan.plan }}
                                            </td>
                                            <td>
                                                {{ plan.price }}
                                            </td>
                                            <td>
                                                {{ plan.planPeriod }}
                                            </td>
                                        </template>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </template>
    </kr-panel>

    <div class="viewDropdown flexRight" @click.prevent="toggle">
        <div class="dropup">
            <button
                id="viewDropdownMenuButton"
                class="width100 flexSpaceBetween align-items"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                aria-label="More Actions"
            >
                <span class="marginRight10"> More Actions </span>
                <span>
                    <i
                        :class="{
                            'fa fa-caret-up': isOpened,
                            'fa fa-caret-down': !isOpened,
                        }"
                    />
                </span>
            </button>

            <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewDropdownMenuButton">
                <ul>
                    <li v-if="!account.isSuspended">
                        <a class="danger-state" href="#" @click.prevent="$refs['SuspendModalComponent'].suspend(account)">
                            <span> <i class="fa fa-archive marginRight8" aria-hidden="true" />Suspend Account </span>
                        </a>
                    </li>

                    <li v-else>
                        <a class="primary-state" href="#" @click.prevent="$refs['ReinstateModalComponent'].reinstate(account)">
                            <span> <i class="fa fa-refresh marginRight8" aria-hidden="true" />Reinstate Account </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <suspendModalComponent ref="SuspendModalComponent" @emit-suspend="account.isSuspended = true" />
    <reinstateModalComponent ref="ReinstateModalComponent" @emit-reinstate="account.isSuspended = false" />
</template>
<script setup>
import { ref, getCurrentInstance } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import suspendModalComponent from '../actions/suspendModal.vue';
import reinstateModalComponent from '../actions/reinstateModal.vue';

const emit = defineEmits(['emitResetPassword']);

const route = useRoute();
const { t } = useI18n();
const { proxy } = getCurrentInstance();

const props = defineProps({
    account: {
        type: Object,
        required: true,
    },
});

const getInfluencerLevelLabel = (influencerLevel) => {
    let idx = _.findIndex(proxy.kr_var.influencerLevels, function (o) {
        return o.value == influencerLevel;
    });
    if (idx != -1) {
        return proxy.kr_var.influencerLevels[idx].description;
    }
    return '-';
};
</script>
