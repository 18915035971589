<template>
    <div v-if="component_done_loading">
        <div v-if="pageType == 'form'" class="marginBottom20">
            <button class="btn btn-success" aria-label="Go to Optional Settings" @click.prevent="nextStep()">
                Go to Optional Settings<i class="fas fa-caret-right marginLeft8" aria-hidden="true"></i>
            </button>
            <button class="btn btn-default" aria-label="Save and Exit" @click.prevent="exit()">
                <i class="fa fa-floppy-o marginRight8" aria-hidden="true"></i>Exit
            </button>
        </div>

        <div v-if="pageType != 'confirmation'" class="pageTitle">
            <h1>Upload Materials</h1>
            <p class="col-md-6">This page lets you upload prework materials for students to review before the main activities begin.</p>
        </div>

        <kr-panel :with-footer="false" :options="{ canToggle: pageType == 'confirmation' }" class="overflowXinherit">
            <template #title>
                <div class="col-xs-12 col-sm-6">
                    <h2>Materials Uploaded ({{ materials.length }})</h2>
                </div>

                <div class="col-xs-12 col-sm-6 flex gap10 marginRight10" :class="{ justifyEnd: !isBelow768px }">
                    <template v-if="pageType == 'confirmation'">
                        <template v-if="mode == 'edit'">
                            <button class="btn btn-default margin0 flexInherit" exact @click.prevent="mode = 'view'">Cancel</button>
                            <button class="btn btn-default margin0 flexInherit" @click.prevent="openFileStack">
                                <i class="fas fa-upload marginRight8" aria-hidden="true"></i>Upload Prework Material
                            </button>
                        </template>
                        <template v-if="mode == 'view'">
                            <button
                                class="btn btn-default margin0 flexInherit"
                                @click.prevent="
                                    mode = 'edit';
                                    init();
                                "
                            >
                                <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true"></i>Edit
                            </button>
                        </template>
                    </template>
                    <template v-else>
                        <button class="btn btn-default margin0 flexInherit" @click.prevent="openFileStack">
                            <i class="fas fa-upload marginRight8" aria-hidden="true"></i> Upload Prework Material
                        </button>
                    </template>
                </div>
            </template>
            <template #content>
                <template v-if="materials.length == 0"> Currently no materials were added. </template>
                <template v-else>
                    <div class="materialsTable table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="minWidth90px width90px maxWidth90px" scope="col">Action</th>
                                    <th v-if="pageType == 'form' || mode == 'edit'" class="width140px maxWidth140px">
                                        <div class="checkboxField">
                                            <input id="selectAllMaterials" type="checkbox" :checked="isAllToggled" @click="toggleAll($event)" />
                                            <label for="selectAllMaterials" class="control-label">Select All</label>
                                        </div>
                                    </th>
                                    <th>File</th>
                                    <th class="minWidth130px width130px maxWidth130px">Size</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(attachment, idx) in materials">
                                    <td class="py-9px">
                                        <div class="dropup">
                                            <button
                                                v-tooltip="'More Actions'"
                                                :id="`viewDropdownMenuButton-${idx}`"
                                                class="btn btn-outline-default icon-only margin0"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                aria-label="More Actions"
                                            >
                                                <i class="fa-solid fa-ellipsis-vertical"></i>
                                            </button>
                                            <div
                                                :id="`dropdown-${idx}`"
                                                class="dropdown-menu dropdown-menu-left dropdown-menu-new"
                                                :aria-labelledby="`viewDropdownMenuButton-${idx}`"
                                            >
                                                <ul :key="idx" role="menu">
                                                    <li role="menuitem">
                                                        <a class="primary-state" target="_blank" :href="attachment.material.url">
                                                            <span>
                                                                <span class="material-symbols-outlined filled marginRight8" aria-hidden="true">
                                                                    switch_account </span
                                                                >Preview Attachment
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li v-if="idx != 0" role="menuitem">
                                                        <a
                                                            class="primary-state"
                                                            href="#"
                                                            @click.prevent="changeOrdering(attachment.order, 'up', attachment.uuid, idx)"
                                                        >
                                                            <span>
                                                                <span class="material-symbols-outlined filled marginRight8" aria-hidden="true">
                                                                    arrow_upward </span
                                                                >Move Up
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li v-if="idx != materials.length - 1" role="menuitem">
                                                        <a
                                                            class="primary-state"
                                                            href="#"
                                                            @click.prevent="changeOrdering(attachment.order, 'down', attachment.uuid, idx)"
                                                        >
                                                            <span>
                                                                <span class="material-symbols-outlined filled marginRight8" aria-hidden="true">
                                                                    arrow_downward </span
                                                                >Move Down
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <hr v-if="pageType == 'form' || mode == 'edit'" />
                                                    <li v-if="pageType == 'form' || mode == 'edit'" role="menuitem">
                                                        <a
                                                            class="danger-state"
                                                            href="#"
                                                            @click.prevent="
                                                                selected_materials_idx = [idx];
                                                                deleteSelectedMaterials();
                                                            "
                                                        >
                                                            <span> <i class="fa fa-trash marginRight8" aria-hidden="true"></i>Remove Attachment </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                    <td v-if="pageType == 'form' || mode == 'edit'">
                                        <div class="checkboxField">
                                            <input v-model="selected_materials_idx" type="checkbox" :value="idx" />
                                        </div>
                                    </td>
                                    <td>
                                        {{ attachment.material.filename }}
                                    </td>
                                    <td>
                                        {{ (attachment.material.size / 1000000).toFixed(2) }}
                                        MB
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="pageType == 'form' || mode == 'edit'" class="col-xs-12 col-md-4 buttonGroup marginTop20">
                        <button
                            class="btn btn-danger"
                            :disabled="selected_materials_idx.length == 0 ? true : undefined"
                            :aria-label="'Remove Selected (' + selected_materials_idx.length + ')'"
                            @click="deleteSelectedMaterials"
                        >
                            <i class="fa fa-trash marginRight8" aria-hidden="true"></i>Remove Selected ({{ selected_materials_idx.length }})
                        </button>
                        <button
                            class="btn btn-default"
                            :disabled="selected_materials_idx.length == 0 ? true : undefined"
                            aria-label="Cancel"
                            @click.prevent="
                                selected_materials_idx = [];
                                selected_materials_idx.splice();
                            "
                        >
                            Cancel
                        </button>
                    </div>
                </template>
            </template>
        </kr-panel>
        <div v-if="pageType == 'form'" class="marginTop10">
            <!--<button class="btn btn-default ">
                <i class="fas fa-caret-left"></i>&nbsp;Back
            </button>-->
            <button class="btn btn-success" aria-label="Go to Optional Settings" @click.prevent="nextStep">
                Go to Optional Settings<i class="fas fa-caret-right marginLeft8" aria-hidden="true"></i>
            </button>
            <button class="btn btn-default" aria-label="Save and Exit" @click.prevent="exit">
                <i class="fa fa-floppy-o marginRight8" aria-hidden="true"></i>Exit
            </button>
        </div>
    </div>
</template>
<script>
import KrForm from '../../../../../../components/forms/form';
import { notify } from '@kyvg/vue3-notification';
import * as filestack from 'filestack-js';
export default {
    props: ['activityObj', 'options'],
    data() {
        return {
            component_done_loading: false,
            form: new KrForm({
                fields: {
                    uuid: '',
                    materials: 'type!:array',
                },
                props: {
                    successMessage: false,
                },
            }),
            pageType: 'form',
            mode: 'view',
            selected_materials_idx: [],
            materialsToDelete: [],
            materials: [], //copy
        };
    },
    computed: {
        isAllToggled() {
            return this.materials.length == this.selected_materials_idx.length && this.materials.length != 0;
        },
    },
    created() {
        var that = this;
        this.pageType = _.get(this.options, 'pageType', 'form');
        //this.activityObj = false;
        /*if(!this.activityObj){
        		this.fetchActivity().then(function(response){
					that.activity = response.data.data;
					that.$emit('activity-updated',that.activity);
					that.init();
					that.component_done_loading = true;
				});
        	}else{*/
        this.init();
        this.component_done_loading = true;
    },
    methods: {
        exit() {
            this.$emit('save-and-exit');
        },
        nextStep() {
            if (this.pageType == 'form') {
                this.$nextTick(function () {
                    this.$emit('next-step');
                });
            } else {
                this.mode = 'view';
            }
        },
        saveAndExit() {
            this.submitMaterials(true, true);
        },
        init(data = false) {
            this.materials = data || _.get(this, 'activityObj.materials');
        },
        toggleAll(event) {
            if (this.isAllToggled) {
                this.selected_materials_idx = [];
            } else {
                this.selected_materials_idx = [];
                for (var i = 0; i < this.materials.length; i++) {
                    this.selected_materials_idx.push(i);
                }
            }
        },
        submitMaterials(data = []) {
            var that = this;
            axios
                .put('activities/' + this.activityObj.uuid + '/materials', { materials: data })
                .then((response) => {
                    notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Materials Updated',
                    });
                    that.$emit('activity-updated', response.data.data, 'materials');
                    that.init(response.data.data);
                })
                .catch((errors) => {
                    notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Failed to upload materials please try again later',
                    });
                });
        },
        /*
        deleteMaterial(idx) {
            this.materialsToDelete.push(this.form.model.materials[idx].uuid);
            this.form.model.materials.splice(idx, 1);
            var index = this.selected_materials_idx.indexOf(idx);
            if (index !== -1) this.selected_materials_idx.splice(index, 1);

            for (var i = idx; i < this.selected_materials_idx.length; i++) {
                //reindex selected questions
                this.selected_materials_idx[i] -= 1;
            }
        },
        */
        deleteSelectedMaterials() {
            var that = this;
            this.selected_materials_idx.sort(function (a, b) {
                return b - a;
            });
            let length = this.selected_materials_idx.length;
            for (var i = length - 1; i >= 0; i--) {
                this.materialsToDelete.push(this.materials[this.selected_materials_idx[i]].uuid);
            }
            axios({ method: 'delete', url: 'activities/' + this.activityObj.uuid + '/materials', data: { materialUuids: this.materialsToDelete } })
                .then(function (response) {
                    notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Materials Deleted',
                    });
                    that.selected_materials_idx = [];
                    that.materialsToDelete = [];
                    that.$emit('activity-updated', response.data.data, 'materials');
                    that.init(response.data.data);
                })
                .catch(() => {
                    that.selected_materials_idx = [];
                    that.materialsToDelete = [];
                    notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to delete materials, please try again later',
                    });
                });
        },
        openFileStack() {
            this.client = filestack.init(process.env.VUE_APP_FILESTACK_API_KEY);
            var that = this;
            this.client
                .picker({
                    accept: [
                        '.jpg',
                        '.jpeg',
                        '.gif',
                        '.png',
                        '.csv',
                        '.doc',
                        '.docx',
                        '.dot',
                        '.dotx',
                        '.key',
                        '.numbers',
                        '.odt',
                        '.odp',
                        '.ods',
                        '.pages',
                        '.pdf',
                        '.ppt',
                        '.pot',
                        '.potx',
                        '.pps',
                        '.ppsx',
                        '.pub',
                        '.rtf',
                        '.sldx',
                        '.txt',
                        '.xls',
                        '.xlt',
                        '.xlsx',
                        '.mp4',
                        '.mp3',
                    ],
                    maxFiles: 20,
                    uploadInBackground: false,
                    onUploadStarted: function (file) {
                        //that.is_uploading = true;
                    },
                    onUploadDone: function (res) {
                        that.submitMaterials(res.filesUploaded);
                    },
                })
                .open();
        },
        changeOrdering(order, type, material_uuid, idx) {
            console.log(order, type, material_uuid);
            var that = this;
            var old_order = idx;
            axios
                .patch(`activities/${this.activityObj.uuid}/materials/${material_uuid}/reorder`, {
                    order: type == 'up' ? order - 1 : order + 1,
                })
                .then(function (response) {
                    that.materials.splice(type == 'up' ? idx - 1 : idx + 1, 0, that.materials.splice(old_order, 1)[0]);
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'The material order has been updated successfully.',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later',
                    });
                });
        },
    },
};
</script>
