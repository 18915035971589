<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <h1>Accounts Overview</h1>

        <cl-tabs-nav :array-value="tabs" @emitCurrentTab="currentTab = $event" />

        <div class="tab-content" id="nav-tabContent">
            <template v-for="tab in tabs">
                <div
                    v-if="tab.show"
                    :key="tab.id"
                    :id="`${tab.id}-panel`"
                    class="tab-pane"
                    :class="{ 'in active': tab.id == currentTab, fade: tab.id != currentTab }"
                    role="tabpanel"
                    :aria-labelledby="`${tab.id}-tab`"
                    :hidden="currentTab != tab.id"
                    :tabindex="currentTab === tab.id ? 0 : -1"
                >
                    <div class="pageTitle">
                        <h2>{{ tab.label }}</h2>
                    </div>

                    <accountOverviewComponent
                        v-if="tab.id == 'account' && currentTab == 'account'"
                        :account="account"
                        @emitResetPassword="openResetPasswordModal"
                    />
                    <superAdminsPanelComponent
                        v-if="tab.id == 'superadmin' && currentTab == 'superadmin'"
                        :account="account"
                        @emitResetPassword="openResetPasswordModal"
                    />
                    <teacherPanelComponent
                        v-if="tab.id == 'teacher' && currentTab == 'teacher'"
                        :account="account"
                        @emitResetPassword="openResetPasswordModal"
                    />
                    <studentPanelComponent
                        v-if="tab.id == 'student' && currentTab == 'student'"
                        :account="account"
                        :coursesList="coursesList"
                        :paidSettings="paidSettings"
                        @emitResetPassword="openResetPasswordModal"
                    />
                    <accessCodeComponent v-if="tab.id == 'code' && currentTab == 'code'" :account="account" />
                    <paymentLogsComponent v-if="tab.id == 'payment-log' && currentTab == 'payment-log'" :account="account" />
                </div>
            </template>
        </div>

        <kr-reset-password />
    </div>
</template>

<script setup>
import { ref, watch, onMounted, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import accountOverviewComponent from './partials/accountOverview.vue';
import superAdminsPanelComponent from './partials/superAdminsPanel.vue';
import teacherPanelComponent from './partials/teacherPanel.vue';
import studentPanelComponent from './partials/studentPanel.vue';
import accessCodeComponent from './partials/accessCode.vue';
import paymentLogsComponent from './partials/paymentLogs.vue';

const route = useRoute();
const { t } = useI18n();

const component_done_loading = ref(false);

const account = ref(null);

const coursesList = ref([]);
const paidSettings = ref(false);

const tabs = ref([]);
const currentTab = ref(route.query.tab || 'account');

watch(
    currentTab,
    (newVal) => {
        breadCrumbTitle(newVal);
    },
    { immediate: true }
);

const fetch = () => {
    axios.get('/accounts/' + route.params.id).then(function (response) {
        account.value = response.data.data;
        coursesList.value = account.value.courses;

        if (['Paid', 'Paid-Trial'].includes(_.get(account.value, 'type'))) {
            if (['Institution'].includes(_.get(account.value, 'paymentMethod'))) {
                paidSettings.value = 2;
            } else {
                paidSettings.value = 3;
            }
        } else {
            paidSettings.value = false;
        }

        tabs.value = [
            {
                id: 'account',
                label: 'Overview',
                show: true,
            },
            {
                id: 'superadmin',
                label: 'Super Admins',
                show: true,
            },
            {
                id: 'teacher',
                label: 'Teachers',
                show: true,
            },
            {
                id: 'student',
                label: 'Students',
                show: true,
            },
            {
                id: 'code',
                label: 'Access Codes',
                show: account.value.type == 'Paid' && account.value.paymentMethod == 'Student-Paid',
            },
            {
                id: 'payment-log',
                label: 'Payment Logs',
                show: account.value.type == 'Paid' && account.value.paymentMethod == 'Student-Paid',
            },
        ];

        component_done_loading.value = true;
    });
};

const breadCrumbTitle = (tab) => {
    var tabName =
        tab == 'account'
            ? 'Overview'
            : tab == 'superadmin'
            ? 'Super Admins'
            : tab == 'teacher'
            ? 'Teachers'
            : tab == 'student'
            ? 'Students'
            : tab == 'code'
            ? 'Access Codes'
            : tab == 'payment-log'
            ? 'Payment Logs'
            : '';

    document.title = `${tabName} | Accounts | InteDashboard | TBL Makes Teams Work`;

    Events.fire('topbar_update', {
        title: '<i class="fas fa-id-card"></i>&nbsp;Accounts',
        breadcrumb: [
            { name: 'Home' },
            { name: t('modules.accounts.listing.breadcrumbLabel'), link: 'accounts.index' },
            { name: account.value.organisationName },
            { name: tabName },
        ],
    });
};

const openResetPasswordModal = (user, module) => {
    if (module == 'account') {
        Events.fire('open-reset-password-modal', {
            user: user.adminTeacherAccount,
            organisation: account.organisationName,
        });
    } else {
        Events.fire('open-reset-password-modal', {
            user: user,
            organisation: account.organisationName,
        });
    }
};

onMounted(() => {
    fetch();
});
</script>
