<template>
    <div
        ref="modal"
        class="modal default-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="detailsModal-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="detailsModal-title" class="modal-title">Details</h2>
                </div>
                <div class="modal-body textAlignCenter">
                    <h2>Details</h2>

                    <pre v-dompurify-html="values" class="textAlignLeft" />
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';

const modal = ref(null);
const values = ref({});

const view = (details, status) => {
    values.value = syntaxHighlight(details, status == 'success' ? 'successColor' : 'dangerColor');
    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const syntaxHighlight = (json, status) => {
    if (typeof json != 'string') {
        json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        var cls = status;
        return '<span class="' + cls + '">' + match + '</span>';
    });
};

defineExpose({ view });
</script>

<style scoped>
pre {
    outline: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
}
</style>
