<template>
    <div
        ref="modal"
        class="modal danger-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="suspendModal-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="suspendModal-title" class="modal-title">Suspend Codes</h2>
                </div>
                <div class="modal-body textAlignCenter">
                    <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                    <h2 class="marginTop40">Suspend Codes</h2>

                    <div class="table-responsive">
                        <table class="table">
                            <caption>
                                List of selected codes ({{
                                    values.length
                                }}
                                {{
                                    values.length == 1 ? 'code' : 'codes'
                                }})
                            </caption>
                            <thead>
                                <tr>
                                    <th>Codes</th>
                                    <th>Validity Period</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(code, idx) in values">
                                    <td class="textAlignLeft">{{ code.code }}</td>
                                    <td class="textAlignLeft">
                                        <template v-if="code.validityPeriod"> {{ code.validityPeriod }} Months </template>
                                        <template v-else> - </template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p class="marginTop20">
                        <strong>Are you sure to suspend the codes?</strong>
                    </p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                    <button class="btn btn-danger" @click.prevent="submit"><i class="fa fa-ban marginRight8" aria-hidden="true" />Yes, Suspend Codes</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';
import { notify } from '@kyvg/vue3-notification';
import { useRoute } from 'vue-router';

const emit = defineEmits(['emitCodesListingRefresh']);

const route = useRoute();
const modal = ref(null);
const values = ref({});

const suspend = (v = {}) => {
    values.value = v;
    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const submit = () => {
    axios
        .put(`/accounts/${route.params.id}/access-codes/bulk/suspend`, {
            data: values.value,
        })
        .then(function (response) {
            closeModal();
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Access Codes have been suspended',
            });

            emit('emitCodesListingRefresh');
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong, please try again later',
            });
        });
};

defineExpose({ suspend });
</script>
