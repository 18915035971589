<template>
    <div
        ref="modal"
        class="modal danger-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="suspendAccountModal-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="suspendAccountModal-title" class="modal-title">Suspend Account</h2>
                </div>
                <div class="modal-body textAlignCenter">
                    <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                    <h2 class="marginTop40">Suspend Account</h2>

                    <h3 class="marginBottom20 fw-normal capitalize">
                        {{ values.organisationName }}
                    </h3>

                    <p class="marginTop20">Suspending this organisation will hide it</p>

                    <p>from your active organisations list.</p>

                    <p class="marginTop20">
                        <i>You can reinstate it later by filtering</i>
                    </p>

                    <p>
                        <i>your organisations and showing Suspended organisations.</i>
                    </p>

                    <p class="marginTop20">
                        <strong>Are you sure to suspend the organisation?</strong>
                    </p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                    <button class="btn btn-danger" @click.prevent="submit">
                        <i class="fa fa-archive marginRight8" aria-hidden="true" />Yes, Suspend Account
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';
import { notify } from '@kyvg/vue3-notification';

const emit = defineEmits(['emit-suspend']);
const modal = ref(null);
const values = ref({});

const suspend = (v = {}) => {
    values.value = v;
    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const submit = () => {
    axios
        .put(`/accounts/${values.value.uuid}/suspend`)
        .then(function (response) {
            closeModal();
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: `You have successfully suspended this organisation`,
            });

            emit('emit-suspend');
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong, please try again later',
            });
        });
};

defineExpose({ suspend });
</script>
