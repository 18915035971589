<template>
    <div>
        <div>
            <h3 class="margin0 fs-18px">Question Stem</h3>
            <kr-math
                :input="question.question"
                :safe="!question.questionIsHTML"
                class="question"
                :can-highlight="true"
                :question-uuid="question.uuid"
            ></kr-math>
        </div>
        <template v-if="question.attachments && question.attachments.length != 0">
            <div class="marginTop20 fontBold"><i class="fa fa-paperclip marginRight8" aria-hidden="true"></i>Question Attachment(s)</div>

            <div class="textLink">
                <div v-for="(file, idx) in question.attachments" :key="idx + '-' + file.filename" class="flex">
                    <i class="fa fa-file-image-o marginRight8" aria-hidden="true"></i>
                    <template
                        v-if="
                            (testObj.type == 'application' && (!isTestCompleted || (isTestCompleted && testObj.allowStudentsToViewAttachments))) ||
                            testObj.type != 'application'
                        "
                    >
                        <template v-if="isPdf(file)">
                            <template v-if="testObj.settings.allowPdfDownload">
                                <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">
                                    {{ file.filename }}
                                </a>
                            </template>
                            <template v-else>
                                <a class="textLink" :aria-label="file.filename" @click.prevent="openViewPdfModal(file)">{{ file.filename }}</a>
                            </template>
                        </template>
                        <template v-else>
                            <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                        </template>
                    </template>
                    <template v-else>
                        <a v-tooltip="{ content: 'Viewing disabled' }" class="textLink disabled" :aria-label="file.filename">{{ file.filename }} </a>
                    </template>
                </div>
            </div>
        </template>

        <div v-if="!testObj._isPreview || testObj._isTeacherPreview" class="marginTop30">
            <fieldset>
                <legend>Question {{ getQuestionNo(question.group - 1, question.order - 1) }} answer</legend>

                <label
                    v-if="!question.canAllowStudentRichTextResponses"
                    :for="
                        question.type == 'vsa'
                            ? question.uuid + '-vsa'
                            : !question.canAllowStudentRichTextResponses
                            ? question.uuid + '-openended'
                            : question.uuid + '-openended-editor'
                    "
                >
                    Fill in your answer:
                </label>
                <div v-if="question.type == 'vsa'">
                    <input
                        :id="question.uuid + '-vsa'"
                        v-model="model"
                        class="form-control vsaTextField"
                        type="text"
                        placeholder="Input your answer here"
                        :disabled="disabled ? true : undefined"
                        :aria-describedby="'vsa-help-' + question.uuid"
                        :name="question.uuid + '-field'"
                        @keydown="keyhandler"
                        @keydown.ctrl.meta.space="onIOSEmojiPanelOpen($event)"
                        @keyup="keyup"
                    />
                    <p v-if="question.vsaSettings.label != ''" :aria-checked="'vsa-help-' + question.uuid" class="displayInlineBlock marginLeft8">
                        {{ question.vsaSettings.label }}
                    </p>
                </div>

                <kr-editor
                    v-else-if="question.canAllowStudentRichTextResponses"
                    :key="question.uuid"
                    v-model="model"
                    :options="{
                        toolbar1: 'bold italic underline strikethrough | bullist numlist outdent indent | table | image | link',
                        toolbar2: '',
                    }"
                    :disabled="disabled ? true : undefined"
                    :aria-describedby="question.uuid + '_numberOfWords ' + question.uuid + '_typing'"
                ></kr-editor>

                <textarea
                    v-else
                    :id="question.uuid + '-openended'"
                    ref="textarea"
                    v-model="model"
                    placeholder="Input your answers here"
                    row="5"
                    cols="50"
                    :disabled="disabled ? true : undefined"
                    :aria-describedby="question.uuid + '_numberOfWords ' + question.uuid + '_typing'"
                    @keydown="keyhandler"
                    @input="textareaResize"
                    @keydown.ctrl.meta.space="onIOSEmojiPanelOpen($event)"
                    @keyup="keyup"
                ></textarea>

                <span v-if="typing" :id="question.uuid + '_typing'" class="greyText fs-16px marginTop10">{{ typing.user.displayName }} is typing...</span>

                <span v-if="question.type != 'vsa'" :id="question.uuid + '_numberOfWords'" class="fs-16px marginTop10" aria-live="polite">
                    <span :style="'color:' + charColor + ';padding-top:5px'">
                        <template v-if="noOfWords < question.minimum && question.minimum != 0">
                            Please enter {{ question.minimum - noOfWords }} more
                            {{ question.minimum - noOfWords == 1 ? 'word' : 'words' }}
                        </template>
                        <template v-else-if="noOfWords <= question.maximum">
                            {{ noOfWords }}
                            {{ noOfWords < 2 ? 'word' : 'words' }} (max {{ question.maximum }})
                        </template>
                        <template v-else-if="noOfWords > question.maximum">
                            {{ noOfWords }}
                            {{ noOfWords < 2 ? 'word' : 'words' }} (max {{ question.maximum }})
                        </template>
                    </span>
                    <span
                        :key="'mcqs-' + question.uuid + '-lastAttempt'"
                        :class="{ floatRight: !isBelow768px }"
                        class="fs-16px marginTop10"
                        aria-live="assertive"
                        v-dompurify-html="displayLastAttemptDate()"
                    >
                    </span>
                </span>

                <span v-else-if="question.type == 'vsa'" class="fs-16px marginTop10" aria-live="assertive" v-dompurify-html="displayLastAttemptDate()"> </span>
            </fieldset>
        </div>
    </div>
</template>

<script>
import { testMixins } from '../../../../../../../../mixins/test';

export default {
    mixins: [testMixins],
    props: ['testObj', 'question', 'value', 'disabled', 'typing', 'isTestCompleted'],

    data() {
        return {
            model: null,
            charColor: '#3c7858',
            noOfWords: 0,
        };
    },
    watch: {
        value: {
            handler: function () {
                this.model = this.value;
            },
            deep: true,
        },
        question: {
            handler: function () {
                this.model = this.value;
            },
            deep: true,
        },
        model: {
            handler(value, oldValue) {
                this.computeNoOfWords();
                if (this.noOfWords < this.question.minimum && this.question.minimum != 0) {
                    this.charColor = '#ca3333';
                } else if (this.noOfWords <= this.question.maximum) {
                    this.charColor = '#717171';
                } else if (this.noOfWords > this.question.maximum) {
                    this.charColor = '#ca3333';
                }
                this.$emit('changed', value);
            },
            deep: true,
        },
    },
    created() {
        this.model = this.value;
    },
    mounted() {
        if (this.$refs.textarea) {
            this.$refs.textarea.style.minHeight = this.$refs.textarea.scrollHeight + 'px';
        }
    },
    methods: {
        displayLastAttemptDate() {
            if (!this.question.studentAnswer.attempts2) {
                return '';
            }
            let attempt2Length = this.question.studentAnswer.attempts2.length;
            let attempt = this.question.studentAnswer.attempts2[attempt2Length - 1];
            if (attempt2Length == 0) {
                return '';
            }
            return `<span>saved this answer at ${this.convertToReadableDate(attempt.timestamp, 'YYYY-MM-DD h:mm A').date} (${
                this.convertToReadableDate(attempt.timestamp, 'YYYY-MM-DD h:mm A').current_timezone
            })</span>`;
        },
        computeNoOfWords() {
            var length = this.model ? this.model.split(/[^\s]+/).length - 1 : 0;
            this.noOfWords = length;
        },
        init() {
            this.model = this.value;
            this.computeNoOfWords();
        },
        keyhandler(event) {
            if (this.question.maximum) {
                if (this.noOfWords == this.question.maximum) {
                    //console.log(event);
                    if (event.keyCode == 46 || event.keyCode == 8) {
                    } else if (event.keyCode == 13 || event.keyCode == 9 || event.keyCode == 32) {
                        event.preventDefault();
                    }
                }
            }
        },
        textareaResize() {
            if (!this.$refs.textarea) {
                return;
            }
            this.$refs.textarea.style.minHeight = this.$refs.textarea.scrollHeight + 'px';
        },
        openViewPdfModal(attachment) {
            var that = this;
            Events.fire('view-pdf-modal', attachment, {
                canDownload: that.testObj.settings.allowPdfDownload,
            });
        },
        keyup(event) {
            this.model = $('[id="' + this.question.uuid + (this.question.type == 'openended' ? '-openended' : '-vsa') + '"]').val();
            var RE_EMOJI = /(\u00a9|\u00ae|[\u2000-\u2FFF]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
            if (this.model) {
                this.model = this.model.replace(RE_EMOJI, '');
            }
        },
        onIOSEmojiPanelOpen(e) {
            e.preventDefault();
        },
    },
};
</script>
