<template>
    <kr-panel :with-footer="false" class="overflowXinherit">
        <template #title>
            <h2>{{ $t('modules.accounts.superadmins.listing.headingLabel') }}</h2>
        </template>
        <template #content>
            <kr-search
                name="superadmins_listing"
                :url="{
                    url: `/accounts/${route.params.id}/superadmins`,
                }"
                :columns="{
                    name: 'display:' + t('modules.accounts.superadmins.listing.fullnameLabel') + '|sortable:false',
                    isSuspended:
                        'display:' +
                        t('modules.accounts.superadmins.listing.statusLabel') +
                        '|sortable:false|width:142px|minWidth:142px|maxWidth:142px|mobile:hide',
                    dateLastLogin:
                        'display:' +
                        t('modules.accounts.superadmins.listing.lastLoginLabel') +
                        '|sortable:false|width:300px|minWidth:300px|maxWidth:300px|mobile:hide',
                    action: 'display:Actions|sortable:false|width:142px|minWidth:142px|maxWidth:142px|mobile_width:142px|mobile_minWidth:142px|mobile_maxWidth:142px',
                }"
                :options="{
                    suspended_field: true,
                    params: { role: '2' },
                    search_field: true,
                    action_column: false,
                    default_query_fields: superAdminSearchData.query,
                    newServerMode: {
                        includes: ['q', 'sort', 'order', 'isSuspended'],
                    },
                    pageName: 'superadmins',
                    removeQuery: true,
                    table_caption: true,
                }"
                @retrieve-data="getSuperAdminSearchData"
            >
                <template #upper-right>
                    <router-link
                        v-slot="{ navigate }"
                        :to="{
                            name: 'accounts.superadmins.create',
                            params: {
                                account_id: account.uuid,
                            },
                        }"
                        custom
                    >
                        <button class="btn btn-primary" @click="navigate">
                            <i class="fa fa-plus marginRight8" aria-hidden="true" />{{ $t('modules.accounts.superadmins.listing.newSuperadminButtonLabel') }}
                        </button>
                    </router-link>
                    <router-link v-slot="{ navigate }" :to="{ name: 'accounts.index' }" custom>
                        <button class="btn btn-default" @click="navigate">
                            <i class="fa fa-caret-left marginRight8" aria-hidden="true" />{{ $t('modules.accounts.superadmins.listing.backButtonLabel') }}
                        </button>
                    </router-link>
                </template>

                <template #top-display-from-to="props">
                    <div class="displayNo">
                        {{ props.model.from }} - {{ props.model.to }} of
                        {{ props.model.meta.paginator.total }}
                    </div>
                </template>

                <template #table-title="props">
                    <th colspan="6">
                        <template v-if="superAdminSearchData.query.isSuspended == '0'"> Active </template>
                        <template v-if="superAdminSearchData.query.isSuspended == '1'"> Suspended </template>
                        <template v-if="superAdminSearchData.query.isSuspended == '*'"> All </template>
                        {{ $t('modules.accounts.superadmins.listing.headingLabel') }} ( {{ props.model.from }} - {{ props.model.to }} of
                        {{ props.model.meta.paginator.total }} )
                    </th>
                </template>

                <template #bottom-display-from-to="props">
                    <div class="displayNo">
                        {{ props.model.from }} - {{ props.model.to }} of
                        {{ props.model.meta.paginator.total }}
                    </div>
                </template>

                <template #table-caption> List of Super Admins in {{ account.organisationName }} </template>

                <template #cdata="props">
                    <td>
                        <router-link
                            v-tooltip="{
                                content: 'View Teacher',
                            }"
                            :to="{
                                name: 'accounts.superadmins.view',
                                params: { id: props.model.uuid },
                            }"
                            class="link"
                        >
                            {{ props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '') }}
                        </router-link>
                        <br />
                        <span class="fontGrey fs-16px">
                            {{ props.model.email ? props.model.email : '-' }}
                        </span>
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <template v-if="!props.model.isSuspended"> <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true" />Active </template>
                        <template v-else> <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Suspended </template>
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <p v-if="props.model.dateLastLogin" class="form-control-static" v-dompurify-html="parseReadableDate(props.model.dateLastLogin, true)" />
                        <p v-else class="form-control-static">-</p>
                    </td>
                    <td>
                        <div class="dropup">
                            <button id="viewDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="Actions">
                                {{ $t('modules.accounts.superadmins.listing.actionsButtonLabel') }}<i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                            </button>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                <ul>
                                    <li>
                                        <router-link
                                            v-tooltip="'View Super Admin'"
                                            class="primary-state"
                                            :to="{
                                                name: 'accounts.superadmins.view',
                                                params: {
                                                    id: props.model.uuid,
                                                },
                                            }"
                                            aria-label="View Super Admin"
                                        >
                                            <span>
                                                <i class="fas fa-eye marginRight8" aria-hidden="true" />{{
                                                    $t('modules.accounts.superadmins.listing.viewButtonLabel')
                                                }}
                                            </span>
                                        </router-link>
                                    </li>

                                    <template v-if="!props.model.isSuspended">
                                        <li>
                                            <a
                                                class="primary-state"
                                                href="#"
                                                aria-label="Reset Password"
                                                @click.prevent="emit('emitResetPassword', props.model, 'teacher')"
                                            >
                                                <span>
                                                    <i class="fa fa-key marginRight8" aria-hidden="true" />{{
                                                        $t('modules.accounts.superadmins.listing.resetPasswordButtonLabel')
                                                    }}
                                                </span>
                                            </a>
                                        </li>

                                        <li>
                                            <a class="primary-state" href="#" @click.prevent="$refs['ImpersonateModalComponent'].impersonate(props.model)">
                                                <span>
                                                    <i class="fa fa-user marginRight8" aria-hidden="true" />{{
                                                        $t('modules.accounts.superadmins.listing.impersonateUserButtonLabel')
                                                    }}
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="superAdminSearchData.meta.paginator.total > 1">
                                            <a class="danger-state" href="#" @click.prevent="$refs['RemoveSuperAdminModalComponent'].remove(props.model)">
                                                <span>
                                                    <i class="fa fa-trash marginRight8" aria-hidden="true" />{{
                                                        $t('modules.accounts.superadmins.listing.removeSuperadminRoleLabel')
                                                    }}
                                                </span>
                                            </a>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </td>
                </template>
            </kr-search>
        </template>
    </kr-panel>

    <removeSuperAdminModalComponent ref="RemoveSuperAdminModalComponent" />
    <impersonateModalComponent ref="ImpersonateModalComponent" />
</template>
<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import removeSuperAdminModalComponent from '../actions/removeModal.vue';
import impersonateModalComponent from '../actions/impersonateModal.vue';

const emit = defineEmits(['emitResetPassword']);

const route = useRoute();
const { t } = useI18n();

const props = defineProps({
    account: {
        type: Object,
        required: true,
    },
});

const superAdminSearchData = ref({
    meta: { paginator: { total: 0 }, filters: {} },
    query: { isSuspended: '0', sort: 'id', order: 'desc' },
});

const getSuperAdminSearchData = (data) => {
    superAdminSearchData.value = data;
};
</script>
