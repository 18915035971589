<template>
    <kr-panel :with-footer="false">
        <template #title> Payment Log </template>
        <template #content>
            <kr-search
                name="payment_log_listing"
                :url="{
                    url: `/accounts/${$route.params.id}/payment-logs`,
                }"
                :columns="{
                    id: 'display:ID|sortable:false|width:98px|minWidth:100px|maxWidth:100px|mobile_width:100px|mobile_minWidth:100px|mobile_maxWidth:100px',
                    name: 'display:Name|sortable:false',
                    type: 'display:Type|width:185px|minWidth:185px|maxWidth:185px|mobile_width:185px|mobile_minWidth:185px|mobile_maxWidth:185px|sortable:false',
                    timestamp:
                        'display:Timestamp|width:280px|minWidth:280px|maxWidth:280px|mobile_width:280px|mobile_minWidth:280px|mobile_maxWidth:280px|sortable:false',
                    status: 'display:Status|width:98px|minWidth:98px|maxWidth:98px|mobile_width:98px|mobile_minWidth:98px|mobile_maxWidth:98px|sortable:false',
                    details:
                        'display:Details|sortable:false|width:120px|minWidth:120px|maxWidth:120px|mobile_width:120px|mobile_minWidth:120px|mobile_maxWidth:120px',
                }"
                :options="{
                    trash_field: false,
                    suspended_field: false,
                    action_column: false,
                    search_field: true,
                    query_fields: query_fields,
                    params: { accountUuid: $route.params.id },
                }"
                @mounted="
                    initStartDatepicker();
                    initEndDatepicker();
                "
            >
                <template #table-title="props">
                    <th colspan="7">All Logs ( {{ props.model.from }} - {{ props.model.to }} of {{ props.model.meta.paginator.total }} )</th>
                </template>
                <template #bottom-display-from-to="props">
                    <div class="displayNo">
                        {{ props.model.from }} - {{ props.model.to }} of
                        {{ props.model.meta.paginator.total }}
                    </div>
                </template>
                <template #cdata="props">
                    <td>
                        {{ props.model.id }}
                    </td>
                    <td>
                        {{ props.model.fullname }}
                        <br />
                        <span class="fontGrey fs-16px">{{ props.model.email }}</span>
                    </td>
                    <td>
                        {{ props.model.paymentMode }}
                    </td>

                    <td>
                        <p v-if="props.model.dateCreated" v-dompurify-html="parseReadableDate(props.model.dateCreated, true)" />
                        <p v-else>-</p>
                    </td>

                    <td class="capitalize" :class="{ dangerColor: props.model.status == 'failed', successColor: props.model.status == 'success' }">
                        {{ props.model.status }}
                    </td>

                    <td>
                        <button
                            v-if="props.model.paymentMethod != 'paypal'"
                            class="btn btn-primary"
                            aria-label="Details"
                            @click.prevent="$refs['detailsModalComponent'].view(props.model.details, props.model.status)"
                        >
                            Details
                        </button>
                        <span v-else :class="props.model.status">{{ props.model.details }}</span>
                    </td>
                </template>
            </kr-search>
        </template>
    </kr-panel>

    <detailsModal ref="detailsModalComponent" />
</template>
<script setup>
import { ref, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import detailsModal from '../actions/detailModal.vue';

const route = useRoute();
const { t } = useI18n();

const props = defineProps({
    account: {
        type: Object,
        required: true,
    },
});

const query_fields = ref({ dateFrom: '', dateTo: '' });

const search = () => {
    Events.fire('log_listing_refresh');
};

const initStartDatepicker = () => {
    let options = {
        maxDate: query_fields.value.dateTo,
        singleDatePicker: true,
        timePicker: false,
        cancelClass: '',
        showDropdowns: true,
        autoUpdateInput: false,
        locale: {
            format: 'YYYY-MM-DD',
        },
    };

    if (query_fields.value.dateFrom != '') {
        options.startDate = moment.utc(query_fields.value.dateFrom).format('YYYY-MM-DD');
    }
    $('input[name="start_log_daterange"]').daterangepicker(options);
    $('input[name="start_log_daterange"]').on('apply.daterangepicker', function (ev, picker) {
        _.set(query_fields.value, 'dateFrom', picker.startDate.format('YYYY-MM-DD'));
        $('input[name="start_log_daterange"]').val(picker.startDate.format('LL'));
        search();
        nextTick(function () {
            initEndDatepicker();
        });
    });
    $('input[name="start_log_daterange"]').on('cancel.daterangepicker', function (ev, picker) {
        $(this).val('');
        _.set(query_fields.value, 'dateFrom', '');
        search();
        nextTick(function () {
            initEndDatepicker();
        });
    });
};

const initEndDatepicker = () => {
    let options = {
        minDate: query_fields.value.dateFrom,
        singleDatePicker: true,
        timePicker: false,
        cancelClass: '',
        showDropdowns: true,
        autoUpdateInput: false,
        locale: {
            format: 'YYYY-MM-DD',
        },
    };
    if (query_fields.value.dateTo != '') {
        options.startDate = moment.utc(query_fields.value.dateTo).format('YYYY-MM-DD');
    }
    $('input[name="end_log_daterange"]').daterangepicker(options);
    $('input[name="end_log_daterange"]').on('apply.daterangepicker', function (ev, picker) {
        _.set(query_fields.value, 'dateTo', picker.startDate.format('YYYY-MM-DD'));
        $('input[name="end_log_daterange"]').val(picker.startDate.format('LL'));
        search();
        nextTick(function () {
            initStartDatepicker();
        });
    });
    $('input[name="end_log_daterange"]').on('cancel.daterangepicker', function (ev, picker) {
        $(this).val('');
        _.set(query_fields.value, 'dateTo', '');
        search();
        nextTick(function () {
            initStartDatepicker();
        });
    });
};
</script>
