<template>
    <div
        ref="modal"
        class="modal primary-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="extendModal-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="extendModal-title" class="modal-title">Extend Codes Validity</h2>
                </div>
                <div class="modal-body textAlignCenter">
                    <h2>Extend Codes Validity</h2>
                    <div class="table-responsive">
                        <table class="table">
                            <caption>
                                List of selected codes ({{
                                    values.length
                                }}
                                {{
                                    values.length == 1 ? 'code' : 'codes'
                                }})
                            </caption>
                            <thead>
                                <tr>
                                    <th>Codes</th>
                                    <th>Validity Period</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(code, idx) in values">
                                    <td class="textAlignLeft">{{ code.code }}</td>
                                    <td class="textAlignLeft">{{ code.validityPeriod }} Months</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p class="col-xs-12 col-md-8 col-md-offset-2 marginTop20">
                        How many more months would you like to extend the validity of these access codes by?
                    </p>

                    <div class="textAlignCenter marginTop20">
                        <label for="extendMonths-input" class="control-label">Set Extend Validity</label>
                        <br />
                        <div class="numberInputButtons">
                            <button
                                class="btn btn-outline-dark"
                                v-tooltip="
                                    months != 0 ? ` Change Extend Validity value to ${months - 1} months` : `Current Value: 0, Cannot have negative value`
                                "
                                :class="{
                                    disabled: months == 0,
                                }"
                                @click.prevent="months == 0 ? '' : months--"
                            >
                                <i class="fas fa-minus" aria-hidden="true" />
                            </button>

                            <input
                                id="extendMonths-input"
                                v-tooltip="months == 0 ? `Extend validity need to be at least 1 month` : `Current Value: ${months}`"
                                type="number"
                                class="form-control"
                                min="0"
                                :value="months"
                                :aria-label="`Set Extend Validity by ${months} months`"
                                @keyup="months = $event.target.value"
                            />

                            <button
                                v-tooltip="`Change Extend Validity value to ${months + 1} months`"
                                class="btn btn-outline-dark margin0"
                                @click.prevent="months++"
                            >
                                <i class="fas fa-plus" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                    <button
                        v-tooltip="months == 0 ? 'At least 1 month is required' : ''"
                        class="btn btn-success"
                        :class="{ 'disabled hasToolTip': months == 0 }"
                        @click.prevent="months == 0 ? '' : submit()"
                    >
                        <i class="fas fa-clock marginRight8" aria-hidden="true" />Yes, Extend Validity
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';
import { notify } from '@kyvg/vue3-notification';
import { useRoute } from 'vue-router';

const emit = defineEmits(['emitCodesListingRefresh']);

const route = useRoute();
const modal = ref(null);
const values = ref({});
const months = ref();

const extend = (v = {}, m = {}) => {
    values.value = v;
    months.value = m;

    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const submit = () => {
    axios
        .put(`/accounts/${route.params.id}/access-codes/bulk/update-period`, {
            months: months.value,
            data: values.value,
        })
        .then(function (response) {
            closeModal();
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Access Codes validity have been extended',
            });

            emit('emitCodesListingRefresh');
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong, please try again later',
            });
        });
};

defineExpose({ extend });
</script>
