<template>
    <div>
        <kr-panel :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <h2>Item Analysis ({{ items.length }})</h2>
            </template>
            <template #content>
                <div v-if="component_done_loading">
                    <div class="hidden-xs hidden-sm table-responsive new-table-container">
                        <div class="scrollable-table-wrapper flex">
                            <div class="new-left-table-scrollbar">
                                <div style="height: 0.1px" />
                            </div>
                            <div class="new-center-table-scrollbar">
                                <div style="height: 0.1px" />
                            </div>
                            <div class="new-right-table-scrollbar">
                                <div style="height: 0.1px" />
                            </div>
                        </div>

                        <div class="scrollable-table-wrapper flex">
                            <div class="new-left-table">
                                <caption class="visible-hidden">
                                    Item analysis table ({{
                                        items.length
                                    }}
                                    questions)
                                </caption>

                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>
                                                Question

                                                <button
                                                    v-if="sort === 'question_no'"
                                                    class="btn sort-button"
                                                    @click.prevent="toggle('question_no')"
                                                    :aria-label="'Sort by question ' + (order === 'asc' ? 'ascending' : 'descending')"
                                                    :aria-sort="order === 'asc' ? 'ascending' : 'descending'"
                                                >
                                                    <i class="fa" :class="order === 'asc' ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc'" aria-hidden="true" />
                                                </button>
                                                <button v-else class="btn sort-button" @click.prevent="toggle('question_no')" :aria-label="'Sort by question'">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </button>
                                            </th>
                                            <th v-if="testObj.others.isApplicationGraded">
                                                Fail Rate

                                                <button
                                                    v-if="sort === 'failRate'"
                                                    class="btn sort-button"
                                                    @click.prevent="toggle('failRate')"
                                                    :aria-label="'Sort by fail rate ' + (order === 'asc' ? 'ascending' : 'descending')"
                                                    :aria-sort="order === 'asc' ? 'ascending' : 'descending'"
                                                >
                                                    <i class="fa" :class="order === 'asc' ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc'" aria-hidden="true" />
                                                </button>
                                                <button v-else class="btn sort-button" @click.prevent="toggle('failRate')" :aria-label="'Sort by fail rate'">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, idx) in items" :key="'application-qns-items' + item.id">
                                            <td>
                                                <button
                                                    class="btn gradient btn-default"
                                                    :aria-label="'row ' + idx + ' Question number ' + item.question_no + ' click to view the question'"
                                                    @click.prevent="openQuestionInfoModal(questions[item.question_no - 1])"
                                                >
                                                    {{ item.question_no }}.<i class="fas fa-info-circle marginLeft8" aria-hidden="true" />
                                                </button>
                                            </td>
                                            <td
                                                v-if="testObj.others.isApplicationGraded"
                                                :aria-label="'row ' + idx + ' Fail Rate ' + round(items[idx].failRate) + '%'"
                                            >
                                                {{ round(items[idx].failRate) }}%
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <scroll-box-shadow class="new-center-table">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th v-for="(value, index) in max_keys" :key="'new-center-table-max-keys' + index">
                                                {{ String.fromCharCode(65 + index) }}

                                                <button
                                                    v-if="sort === 'options.' + index + '.percent'"
                                                    class="btn sort-button"
                                                    @click.prevent="toggle('options.' + index + '.percent')"
                                                    :aria-label="
                                                        'Sort by option ' +
                                                        String.fromCharCode(65 + index) +
                                                        ' ' +
                                                        (order === 'asc' ? 'ascending' : 'descending')
                                                    "
                                                    :aria-sort="order === 'asc' ? 'ascending' : 'descending'"
                                                >
                                                    <i class="fa" :class="order === 'asc' ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc'" aria-hidden="true" />
                                                </button>
                                                <button
                                                    v-else
                                                    class="btn sort-button"
                                                    @click.prevent="toggle('options.' + index + '.percent')"
                                                    :aria-label="'Sort by option ' + String.fromCharCode(65 + index)"
                                                >
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </button>
                                            </th>
                                            <template v-if="max_keys == 0">
                                                <th>
                                                    {{ String.fromCharCode(65) }}
                                                </th>
                                                <th>
                                                    {{ String.fromCharCode(66) }}
                                                </th>
                                                <th>
                                                    {{ String.fromCharCode(67) }}
                                                </th>
                                                <th>
                                                    {{ String.fromCharCode(68) }}
                                                </th>
                                            </template>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(question, idx) in items" :key="'new-center-table-items' + question.id">
                                            <template v-if="!['openended', 'vsa', 'matching', 'sequence'].includes(question.question.type)">
                                                <template v-for="(option, option_idx) in question.options" :key="question + '-' + '-option-' + option_idx">
                                                    <td
                                                        :aria-label="
                                                            option.isCorrect
                                                                ? 'row ' +
                                                                  idx +
                                                                  ' option ' +
                                                                  option.key +
                                                                  ' ' +
                                                                  `${round(option.percent)}` +
                                                                  '% is correct answer'
                                                                : 'row ' + idx + ' option ' + option.key + ' ' + `${round(option.percent)}` + '%'
                                                        "
                                                        tabindex="0"
                                                    >
                                                        <div
                                                            :class="{
                                                                minWidth82px: !option.isCorrect && !optionCorrect(question.question, option.key),
                                                                'statusTag  whiteSpaceNowrap paddingBottom3':
                                                                    option.isCorrect || optionCorrect(question.question, option.key),
                                                                tagCorrect: option.isCorrect,
                                                                tagNewCorrect: optionCorrect(question.question, option.key),
                                                            }"
                                                        >
                                                            <span class="paddingTop2">{{ round(option.percent) }}%</span>
                                                            <i
                                                                v-if="option.isCorrect || optionCorrect(question.question, option.key)"
                                                                class="fas fa-check-circle marginLeft8"
                                                                aria-hidden="true"
                                                            />
                                                        </div>
                                                    </td>
                                                </template>
                                                <td
                                                    v-for="(n, index) in max_keys - question.options.length"
                                                    :aria-label="'row ' + idx + ' option ' + String.fromCharCode(n) + ' ' + ' this is empty'"
                                                />
                                            </template>
                                            <template v-else>
                                                <template v-if="max_keys != 0">
                                                    <td :colspan="max_keys" style="text-align: center">N/A</td>
                                                </template>
                                                <template v-else>
                                                    <td style="text-align: center">N/A</td>
                                                    <td style="text-align: center">N/A</td>
                                                    <td style="text-align: center">N/A</td>
                                                    <td style="text-align: center">N/A</td>
                                                </template>
                                            </template>
                                        </tr>
                                    </tbody>
                                </table>
                            </scroll-box-shadow>

                            <div class="new-right-table">
                                <table class="table">
                                    <thead>
                                        <tr class="sticky">
                                            <th v-if="testObj.others.isApplicationGraded" class="minWidth100px maxWidth100px width100px">
                                                Points

                                                <button
                                                    v-if="sort === 'score'"
                                                    class="btn sort-button"
                                                    @click.prevent="toggle('score')"
                                                    :aria-label="'Sort by points ' + (order === 'asc' ? 'ascending' : 'descending')"
                                                    :aria-sort="order === 'asc' ? 'ascending' : 'descending'"
                                                >
                                                    <i class="fa" :class="order === 'asc' ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc'" aria-hidden="true" />
                                                </button>
                                                <button v-else class="btn sort-button" @click.prevent="toggle('score')" :aria-label="'Sort by points'">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </button>
                                            </th>
                                            <th class="minWidth130px maxWidth130px width130px">
                                                Avg. Time

                                                <button
                                                    v-if="sort === 'nonFormattedAverageCompletionTimePerQuestion'"
                                                    class="btn sort-button"
                                                    @click.prevent="toggle('nonFormattedAverageCompletionTimePerQuestion')"
                                                    :aria-label="'Sort by average time ' + (order === 'asc' ? 'ascending' : 'descending')"
                                                    :aria-sort="order === 'asc' ? 'ascending' : 'descending'"
                                                >
                                                    <i class="fa" :class="order === 'asc' ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc'" aria-hidden="true" />
                                                </button>
                                                <button
                                                    v-else
                                                    class="btn sort-button"
                                                    @click.prevent="toggle('nonFormattedAverageCompletionTimePerQuestion')"
                                                    :aria-label="'Sort by average time'"
                                                >
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </button>
                                            </th>
                                            <th v-if="testObj.others.isApplicationGraded" class="minWidth120px maxWidth120px width120px">
                                                Learners
                                                <br />
                                                Finished

                                                <button
                                                    v-if="sort === 'percentFinishedLearners'"
                                                    class="btn sort-button"
                                                    @click.prevent="toggle('percentFinishedLearners')"
                                                    :aria-label="'Sort by learners finished time ' + (order === 'asc' ? 'ascending' : 'descending')"
                                                    :aria-sort="order === 'asc' ? 'ascending' : 'descending'"
                                                >
                                                    <i class="fa" :class="order === 'asc' ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc'" aria-hidden="true" />
                                                </button>
                                                <button
                                                    v-else
                                                    class="btn sort-button"
                                                    @click.prevent="toggle('percentFinishedLearners')"
                                                    :aria-label="'Sort by learners finished time'"
                                                >
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </button>
                                            </th>
                                            <th v-if="testObj.others.isApplicationGraded" class="minWidth125px maxWidth125px width125px">
                                                % correct

                                                <button
                                                    v-if="sort === 'percentCorrect'"
                                                    class="btn sort-button"
                                                    @click.prevent="toggle('percentCorrect')"
                                                    :aria-label="'Sort by precent correct ' + (order === 'asc' ? 'ascending' : 'descending')"
                                                    :aria-sort="order === 'asc' ? 'ascending' : 'descending'"
                                                >
                                                    <i class="fa" :class="order === 'asc' ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc'" aria-hidden="true" />
                                                </button>
                                                <button
                                                    v-else
                                                    class="btn sort-button"
                                                    @click.prevent="toggle('percentCorrect')"
                                                    :aria-label="'Sort by percent correct'"
                                                >
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(question, idx) in items">
                                            <td v-if="testObj.others.isApplicationGraded" :aria-label="'row ' + idx + ' ' + question.question.score + 'points'">
                                                {{ question.question.score }}
                                            </td>
                                            <td :aria-label="'row ' + idx + ' Average Time ' + question.averageCompletionTimePerQuestion">
                                                {{ question.averageCompletionTimePerQuestion }}
                                            </td>
                                            <td
                                                v-if="testObj.others.isApplicationGraded"
                                                :aria-label="'row ' + idx + ' Leaner finished ' + `${round(question.percentFinishedLearners)}` + '%'"
                                            >
                                                {{ round(question.percentFinishedLearners) }}%
                                            </td>
                                            <td
                                                v-if="testObj.others.isApplicationGraded"
                                                :aria-label="'row ' + idx + ' ' + `${round(question.percentCorrect)}` + '% correct'"
                                            >
                                                {{ round(question.percentCorrect) }}%
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="scrollable-table-wrapper flex">
                            <div class="new-left-table-scrollbar">
                                <div style="height: 0.1px" />
                            </div>
                            <div class="new-center-table-scrollbar">
                                <div style="height: 0.1px" />
                            </div>
                            <div class="new-right-table-scrollbar">
                                <div style="height: 0.1px" />
                            </div>
                        </div>
                    </div>

                    <div class="hidden-md hidden-lg">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>
                                        Fail Rate

                                        <button
                                            v-if="sort === 'failRate'"
                                            class="btn sort-button"
                                            @click.prevent="toggle('failRate')"
                                            :aria-label="'Sort by fail rate ' + (order === 'asc' ? 'ascending' : 'descending')"
                                            :aria-sort="order === 'asc' ? 'ascending' : 'descending'"
                                        >
                                            <i class="fa" :class="order === 'asc' ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc'" aria-hidden="true" />
                                        </button>
                                        <button v-else class="btn sort-button" @click.prevent="toggle('failRate')" :aria-label="'Sort by fail rate'">
                                            <i class="fa fa-sort" aria-hidden="true" />
                                        </button>
                                    </th>
                                    <th>
                                        Question

                                        <button
                                            v-if="sort === 'question_no'"
                                            class="btn sort-button"
                                            @click.prevent="toggle('question_no')"
                                            :aria-label="'Sort by question ' + (order === 'asc' ? 'ascending' : 'descending')"
                                            :aria-sort="order === 'asc' ? 'ascending' : 'descending'"
                                        >
                                            <i class="fa" :class="order === 'asc' ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc'" aria-hidden="true" />
                                        </button>
                                        <button v-else class="btn sort-button" @click.prevent="toggle('question_no')" :aria-label="'Sort by question'">
                                            <i class="fa fa-sort" aria-hidden="true" />
                                        </button>
                                    </th>
                                    <th colspan="2">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, idx) in items">
                                    <td>{{ round(item.failRate) }}%</td>
                                    <td>
                                        <button
                                            class="btn gradient btn-default"
                                            :aria-label="'row ' + idx + ' Question number ' + item.question_no + ' click to view the question'"
                                            @click.prevent="openQuestionInfoModal(questions[item.question_no - 1])"
                                        >
                                            {{ item.question_no }}<i class="fas fa-info-circle marginLeft8" aria-hidden="true" />
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            class="btn btn-primary"
                                            :aria-label="questions[item.question_no - 1] + ' Information'"
                                            @click.prevent="openMoreInfoModal(item)"
                                        >
                                            <i class="fas fa-angle-right" aria-hidden="true" />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <vcl-table v-else />
            </template>
        </kr-panel>

        <div
            id="itemAnalysisMoreInfoModal"
            class="modal default-modal"
            data-backdrop="false"
            tabindex="-1"
            role="dialog"
            aria-labelledby="itemAnalysisMoreInfoModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="itemAnalysisMoreInfoModal-title" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true"></i>View Details
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="flex itemAnalysisTable">
                            <div v-if="!['openended', 'vsa'].includes(previewQuestionDetails.type)" class="col-xs-8 col-sm-6 col-md-4">
                                <table class="table">
                                    <tr v-for="(option, option_idx) in previewQuestion.options">
                                        <th>{{ option.key }}</th>
                                        <td
                                            :aria-label="
                                                option.isCorrect
                                                    ? 'row ' + option_idx + ' option ' + option.key + ' ' + `${round(option.percent)}` + '% is correct answer'
                                                    : 'row ' + option_idx + ' option ' + option.key + ' ' + `${round(option.percent)}` + '%'
                                            "
                                            tabindex="0"
                                        >
                                            <div
                                                :class="{
                                                    minWidth82px: !option.isCorrect && !optionCorrect(previewQuestion.question, option.key),
                                                    'statusTag  whiteSpaceNowrap paddingBottom3':
                                                        option.isCorrect || optionCorrect(previewQuestion.question, option.key),
                                                    tagCorrect: option.isCorrect,
                                                    tagNewCorrect: optionCorrect(previewQuestion.question, option.key),
                                                }"
                                            >
                                                <span class="paddingTop2">{{ round(option.percent) }}%</span>
                                                <i
                                                    v-if="option.isCorrect || optionCorrect(previewQuestion.question, option.key)"
                                                    class="fas fa-check-circle marginLeft8"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <div class="col-xs-4 col-sm-4 col-md-4">
                                <div class="row">
                                    <div v-if="testObj.others.isApplicationGraded" class="card">
                                        <div class="card-header">Points</div>
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{ previewQuestionDetails.score }}
                                            </p>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header">Avg. Time</div>
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{ previewQuestion.averageCompletionTimePerQuestion }}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div v-if="testObj.others.isApplicationGraded" class="card">
                                        <div class="card-header">Leaner Finished</div>
                                        <div class="card-body">
                                            <p class="card-text">{{ round(previewQuestion.percentFinishedLearners) }}%</p>
                                        </div>
                                    </div>

                                    <div v-if="testObj.others.isApplicationGraded" class="card">
                                        <div class="card-header">% Correct</div>
                                        <div class="card-body">
                                            <p class="card-text">{{ round(previewQuestion.percentCorrect) }}%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12 buttonGroup marginTop30">
                            <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="itemAnalysisQuestionInfoModal"
            class="modal default-modal"
            data-backdrop="false"
            tabindex="-1"
            role="dialog"
            aria-labelledby="itemAnalysisQuestionInfoModal-title"
        >
            <div class="modal-dialog" :class="{ 'widthAuto maxWidth1000px': ['mcqs', 'mcqm'].includes(previewQuestion.type) }">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="itemAnalysisQuestionInfoModal-title" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true"></i>View Question
                        </h2>
                    </div>
                    <div class="modal-body">
                        <label class="control-label"> Question {{ getQuestionNo(previewQuestion.group - 1, previewQuestion.order - 1) }} </label>

                        <question-display :test-obj="testObj" :question="previewQuestion" />

                        <div v-if="['mcqs', 'mcqm'].includes(previewQuestion.type)" class="mcqStatisticsBarChart marginTop20">
                            <div class="panel-header">
                                <div>Answer Choices</div>
                            </div>
                            <div>
                                <table class="barChart">
                                    <tbody class="body">
                                        <template v-for="(option, option_idx) in previewQuestion.optionKeys">
                                            <tr>
                                                <div class="percentage" :style="'bottom:' + (option.percent.toFixed(0) / 100) * 80 + '%'">
                                                    {{ round(option.percent) }}%
                                                </div>
                                                <td
                                                    class="bars"
                                                    :class="{
                                                        correct: option.isCorrect,
                                                        wrong: !option.isCorrect,
                                                    }"
                                                    :style="'height:' + (option.percent.toFixed(0) / 100) * 80 + '%;'"
                                                />
                                                <td class="record">
                                                    <div>Choice {{ option.key }}</div>
                                                    <template v-for="(model, model_idx) in option.answersPerOption">
                                                        <template v-if="model.student">
                                                            <div
                                                                v-tooltip="model.student.fullname"
                                                                class="btn btnDiscussionTeams marginTop10 cursor-norm"
                                                                tabindex="0"
                                                            >
                                                                <i class="fa fa-user marginRight8" aria-hidden="true" />
                                                                <label>{{ model.student.fullname }}</label>
                                                            </div>
                                                        </template>
                                                        <template v-if="model.team">
                                                            <div
                                                                v-tooltip="model.team.name"
                                                                class="btn btnDiscussionTeams marginTop10 cursor-norm"
                                                                tabindex="0"
                                                            >
                                                                <i class="fa fa-user marginRight8" aria-hidden="true" />
                                                                <label>{{ model.team.name }}</label>
                                                            </div>
                                                        </template>
                                                    </template>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <!-- <div v-if="previewQuestion.remarks" class="gradient panel marginTop10">
                            <div class="panel-heading padding10">
                                <label>Question Notes</label>
                            </div>
                            <div class="panel-body padding10">
                                <p class="whiteSpaceBreakSpacesText">
                                    {{ previewQuestion.remarks }}
                                </p>
                            </div>
                        </div> -->

                        <div class="col-xs-12 buttonGroup marginTop30">
                            <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { VclTable } from 'vue-content-loading';
import { testMixins } from '../../../../../../../../mixins/test';

export default {
    mixins: [testMixins],
    props: ['testObj'],
    data() {
        return {
            component_done_loading: false,
            questions: [],
            max_keys: 0,
            previewQuestion: {},
            previewQuestionDetails: {
                score: null,
                type: '',
            },
            order: 'desc',
            sort: 'failRate',
            itemAnalysis: null,
            items: [],
            debouncedUpdate: null,
            lastUpdateTimestamp: '',
        };
    },
    watch: {
        order: {
            handler: function () {
                this.alignTable();
            },
            immediate: true,
            deep: true,
        },
    },
    created() {
        var that = this;
        this.debouncedUpdate = _.debounce(
            function () {
                that.fetchTest().then(function (response) {
                    response.data.data.itemAnalysis.item.forEach(function (itm) {
                        itm.id = itm.question.id;
                    });
                    that.lastUpdateTimestamp = moment.utc(response.headers['date'], 'ddd, DD MMM YYYY HH:mm:ss [GMT]', true).format('YYYY-MM-DD HH:mm:ss');
                    if (that.itemAnalysis == null) {
                        that.testObj.itemAnalysis = _.cloneDeep(response.data.data.itemAnalysis);
                        //Vue.set(that.testObj, 'itemAnalysis', _.cloneDeep(response.data.data.itemAnalysis));
                        that.itemAnalysis = _.cloneDeep(response.data.data.itemAnalysis);
                    } else {
                        that.itemAnalysis.item = that.mergeArrayOfObjectsWithKey(that.itemAnalysis.item, response.data.data.itemAnalysis.item, 'id');
                    }
                    that.init();
                    if (!that.component_done_loading) {
                        that.component_done_loading = true;
                        that.$nextTick(function () {
                            $('#itemAnalysisQuestionInfoModal .modal-dialog').draggable({
                                handle: '.modal-header',
                            });
                        });
                    }
                });
            },
            1000,
            { maxWait: 3000 }
        );
    },
    mounted() {
        var that = this;

        this.debouncedUpdate();
        this.processEchoListeners();
        $(window).on('resize.alignTable', function () {
            that.alignTable();
        });

        this.$root.$on('fontResized', () => {
            that.alignTable();
        });
    },
    updated() {
        this.alignTable();
    },
    beforeUnmount() {
        $(window).off('resize.alignTable');
    },
    methods: {
        processEchoListeners() {
            var that = this;

            let h1 = (e) => {
                if (e.testUuid != that.testObj.uuid) {
                    return false;
                }
                that.debouncedUpdate();
            };
            if (this.testObj.others.applicationType == 'team') {
                let c1 = window.Echo.private(`test.${this.testObj.uuid}.teacher`).listen('TeamAnswerSaved', h1);
                this.echoChannels.push({
                    channel: c1,
                    event: 'TeamAnswerSaved',
                    handler: h1,
                });
            } else {
                let c1 = window.Echo.private(`test.${this.testObj.uuid}.teacher`).listen('StudentAnswerSaved', h1);
                this.echoChannels.push({
                    channel: c1,
                    event: 'StudentAnswerSaved',
                    handler: h1,
                });
            }
        },
        fetchTest() {
            return axios.get(
                '/tests/' + this.$route.params.id + '/dashboard/items' + (this.lastUpdateTimestamp != '' ? `?timestamp=${this.lastUpdateTimestamp}` : ''),
                { _internal: { hasLoadingBar: false } }
            );
        },
        init() {
            if (_.isEmpty(this.itemAnalysis)) {
                this.itemAnalysis = { item: [] };
            }
            if (_.isEmpty(this.questions)) {
                this.questions = _.flatten(this.testObj.questions);
            }
            for (var i = 0; i < this.itemAnalysis.item.length; i++) {
                if (this.testObj.others.isApplicationGraded) {
                    this.itemAnalysis.item[i].score = this.itemAnalysis.item[i].question.score;
                }
                if (['mcqs', 'mcqm'].includes(this.itemAnalysis.item[i].question.type) && this.itemAnalysis.item[i].options.length > this.max_keys) {
                    this.max_keys = this.itemAnalysis.item[i].options.length;
                }
            }
            this.items = _.clone(this.itemAnalysis.item);
            /*for(var i  = 0; i <this.items.length;i++){
					this.items[i].question_no = i+1;
				}*/
            this.sortList();
            var that = this;
            Vue.nextTick(function () {
                that.alignTable();

                $('.new-left-table-scrollbar').on('scroll', function () {
                    $('.new-left-table').scrollLeft($(this).scrollLeft());
                });

                $('.new-left-table').on('scroll', function () {
                    $('.new-left-table-scrollbar').scrollLeft($(this).scrollLeft());
                });

                $('.new-center-table-scrollbar').on('scroll', function () {
                    $('.new-center-table div').scrollLeft($(this).scrollLeft());
                });

                $('.new-center-table div').on('scroll', function () {
                    $('.new-center-table-scrollbar').scrollLeft($(this).scrollLeft());
                });

                $('.new-right-table-scrollbar').on('scroll', function () {
                    $('.new-right-table').scrollLeft($(this).scrollLeft());
                });

                $('.new-right-table').on('scroll', function () {
                    $('.new-right-table-scrollbar').scrollLeft($(this).scrollLeft());
                });
            });
        },
        sortList() {
            this.items = _.orderBy(this.items, [this.sort], [this.order]);

            var that = this;
            Vue.nextTick(function () {
                that.alignTable();
            });
        },
        toggle(column = '') {
            if (this.sort == column) {
                if (this.order == 'asc') {
                    this.order = 'desc';
                } else {
                    this.order = 'asc';
                }
            } else {
                this.order = 'asc';
                this.sort = column;
            }
            this.sortList();
        },
        openQuestionInfoModal(question) {
            this.previewQuestion = question;
            $('#itemAnalysisQuestionInfoModal').modal('show');
        },
        openMoreInfoModal(question) {
            this.previewQuestion = question;
            this.previewQuestionDetails.score = this.previewQuestion.question.score;
            this.previewQuestionDetails.type = this.previewQuestion.question.type;
            $('#itemAnalysisMoreInfoModal').modal('show');
        },
        alignTable() {
            // new table
            $('.new-left-table thead tr').each(function (index) {
                var theadLeftHeight = $(this).height();
                var theadCenter = $('.new-center-table thead tr:eq(' + index + ')');
                var theadRight = $('.new-right-table thead tr:eq(' + index + ')');

                if (!theadCenter.length) {
                    return false;
                } else if (!theadRight.length) {
                    return false;
                }

                var theadCenterHeight = theadCenter.height();
                var theadRightHeight = theadRight.height();

                if (theadLeftHeight >= theadCenterHeight && theadLeftHeight >= theadRightHeight) {
                    theadCenter.height(theadLeftHeight);
                    theadRight.height(theadLeftHeight);
                } else if (theadCenterHeight >= theadCenterHeight && theadCenterHeight >= theadRightHeight) {
                    $(this).height(theadCenterHeight);
                    theadRight.height(theadCenterHeight);
                } else {
                    $(this).height(theadRightHeight);
                    theadCenter.height(theadRightHeight);
                }
            });

            $('.new-left-table tbody tr').each(function (index) {
                var rowLeftHeight = $(this).height();
                var rowCenter = $('.new-center-table tbody tr:eq(' + index + ')');
                var rowRight = $('.new-right-table tbody tr:eq(' + index + ')');

                if (!rowCenter.length) {
                    return false;
                } else if (!rowRight.length) {
                    return false;
                }

                var rowCenterHeight = rowCenter.height();
                var rowRightHeight = rowRight.height();

                if (rowLeftHeight >= rowCenterHeight && rowLeftHeight >= rowRightHeight) {
                    rowCenter.height(rowLeftHeight);
                    rowRight.height(rowLeftHeight);
                } else if (rowCenterHeight >= rowLeftHeight && rowCenterHeight >= rowRightHeight) {
                    $(this).height(rowCenterHeight);
                    rowRight.height(rowCenterHeight);
                } else {
                    $(this).height(rowRightHeight);
                    rowCenter.height(rowRightHeight);
                }
            });

            $('.new-right-table').scroll(function () {
                $('.new-left-table').scrollTop($(this).scrollTop());
                $('.new-center-table div').scrollTop($(this).scrollTop());
            });

            $('.new-center-table div').scroll(function () {
                $('.new-left-table').scrollTop($(this).scrollTop());
                $('.new-right-table').scrollTop($(this).scrollTop());
            });

            $('.new-left-table').scroll(function () {
                $('.new-center-table div').scrollTop($(this).scrollTop());
                $('.new-right-table').scrollTop($(this).scrollTop());
            });

            var widthOfTheLeftTable = $('.new-table-container .new-left-table table').width();
            $('.new-table-container .new-left-table-scrollbar div').width(widthOfTheLeftTable + 'px');

            var widthOfTheCenterTable = $('.new-table-container .new-center-table table').width();
            $('.new-table-container .new-center-table-scrollbar div').width(widthOfTheCenterTable + 'px');

            var widthOfTheRightTable = $('.new-table-container .new-right-table table').width();
            $('.new-table-container .new-right-table-scrollbar div').width(widthOfTheRightTable + 'px');
        },
        optionCorrect(question, key) {
            if (['ended', 'completed'].includes(this.testObj.status) && question.type == 'mcqs') {
                for (var i = 0; i < this.testObj.acceptedNewAnswers.length; i++) {
                    if (this.testObj.acceptedNewAnswers[i].activityQuestionId == question.id) {
                        for (var j = 0; j < this.testObj.acceptedNewAnswers[i].acceptedAnswers.length; j++) {
                            if (this.testObj.acceptedNewAnswers[i].acceptedAnswers[j] == key) {
                                return true;
                            }
                        }
                    }
                }
            }
        },
    },
    components: {
        'question-display': require(`./../../../../../../questions/partials/question-display.vue`).default,
        VclTable,
    },
};
</script>

<style lang="scss" scoped>
.tablewDiv .table {
    margin-bottom: 0;
    position: relative;
}

.tablewDiv .tableDiv {
    overflow-x: auto;
    height: max-content;
}
.tablewDiv .tableDiv .table {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
}

.tablewDiv .tableDiv1 .table {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 2px 0 3px 0 rgba(174, 174, 174, 0.5);
}

.tablewDiv .table650px .table {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: -2px 0 3px 0 rgba(174, 174, 174, 0.5);
}

.tableDiv1 {
    width: 240px;
}

.table650px {
    width: 650px;
}

.tablewDiv .tableDiv {
    width: calc(100% - 890px);
}

.tableDiv1 .table > thead > tr > th,
.tableDiv1 .table > thead > tr > td,
.tableDiv1 .table > tbody > tr > th,
.tableDiv1 .table > tbody > tr > td,
.tableDiv1 .table > tfoot > tr > th,
.tableDiv1 .table > tfoot > tr > th,
.table650px .table > thead > tr > th,
.table650px .table > thead > tr > td,
.table650px .table > tbody > tr > th,
.table650px .table > tbody > tr > td,
.table650px .table > tfoot > tr > th,
.table650px .table > tfoot > tr > th,
.tableDiv .table > thead > tr > th,
.tableDiv .table > thead > tr > td,
.tableDiv .table > tbody > tr > th,
.tableDiv .table > tbody > tr > td,
.tableDiv .table > tfoot > tr > th,
.tableDiv .table > tfoot > tr > th {
    padding: 15px;
}
thead tr.sticky th {
    position: sticky;
    top: 0;
    z-index: 30;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
}
table {
    border-top: 0;
}
#header-fixed {
    position: relative;
    top: 0px;
    display: none;
    background-color: white;
    z-index: 31;
    overflow-x: auto;
    border: none;
}

#tableDivOG::-webkit-scrollbar {
    width: 0px;
    height: 0;
    background: transparent;
}

.tablewDiv .tableDiv1 .table {
    border-left: 0;
    box-shadow: none;
    border-bottom: 0;
}

.tablewDiv .tableDiv .table {
    box-shadow: none;
    border-bottom: 0;
}

.tablewDiv .table650px .table {
    box-shadow: none;
    border-bottom: 0;
}

#itemAnalysisApp {
    border: 1px solid #ddd;
    border-radius: 3px;
}

/* new table */

.new-table-container {
    table {
        border: 0;

        thead {
            position: sticky;
            top: 0;

            th {
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
            }

            &:first-child {
                & > tr:first-child th {
                    border-top: inherit;
                }
            }
        }

        tbody {
            tr:first-child {
                td {
                    border-top: 0;
                }
            }
        }
    }

    .new-left-table {
        min-width: 240px;
        width: 240px;
        max-height: 500px;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .new-center-table {
        min-width: calc(100% - 240px - 475px);
        width: calc(100% - 240px - 475px);
        max-height: 500px;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .new-right-table {
        width: 475px;
        max-height: 500px;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: 0;
            width: 5px;
            background: #d8d8d8;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background-color: #989b9c;
            border-radius: 2px;
        }
    }

    .new-left-table-scrollbar {
        width: 240px;
    }

    .new-center-table-scrollbar {
        width: calc(100% - 240px - 475px);
    }

    .new-right-table-scrollbar {
        width: 475px;
    }

    .scrollable-table-wrapper {
        border-radius: 3px;
        overflow: hidden;

        &:first-child,
        &:last-child {
            .new-left-table-scrollbar,
            .new-center-table-scrollbar,
            .new-right-table-scrollbar {
                border: 0;
            }
        }
        & > div {
            border: 1px solid #ddd;
            overflow-x: auto;

            &:not(:first-child) {
                border-left: 0;
            }
        }
    }
}
</style>
