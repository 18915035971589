<template>
    <kr-panel v-if="component_done_loading" :with-footer="false">
        <template #title>
            <div class="col-xs-12 col-sm-6">
                <h2>Collaborators</h2>
            </div>

            <div class="col-xs-12 col-sm-6 flex gap10" :class="{ justifyEnd: !isBelow768px }">
                <button
                    v-if="myCollaboratorRole.role != 'Course Owner'"
                    class="btn btn-outline-danger flexInherit margin0"
                    @click.prevent="$refs['leaveCollaboratorComponent'].leave(course)"
                >
                    <i class="fa fa-sign-out-alt marginRight8" aria-hidden="true" /> Leave Collaborator
                </button>
                <router-link
                    v-if="['Course Owner', 'Course Admin'].includes(myCollaboratorRole.role)"
                    v-slot="{ navigate }"
                    :to="{
                        name: 'courses.manage.collaborators.create',
                        params: { id: course.uuid },
                        query: {
                            courseName: course.name,
                            courseCode: course.code,
                            listOfUnassignedStaff: JSON.stringify(unassignedStaffs),
                        },
                    }"
                    custom
                >
                    <button class="btn btn-primary flexInherit margin0" @click="navigate">
                        <i class="fa fa-plus marginRight8" aria-hidden="true" />Add Collaborator
                    </button>
                </router-link>

                <div class="dropdown">
                    <button
                        id="viewDropdownMenuButton"
                        class="btn drop-down margin0 flexInherit"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        More Actions<i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                    </button>
                    <div
                        class="dropdown-menu dropdown-menu-new"
                        :class="{ 'dropdown-menu-right': !isBelow768px, 'dropdown-menu-left': isBelow768px }"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <ul>
                            <li>
                                <a class="primary-state" href="#" @click.prevent="downloadCollaborators">
                                    <span> <i class="fas fa-download marginRight8" aria-hidden="true" /> Export Collaborators List </span>
                                </a>
                            </li>
                            <li v-if="$can('manage_course_collaborators', 'Course-' + course.uuid)">
                                <a class="primary-state" href="#" @click.prevent="$refs['viewLogsComponent'].view(course)">
                                    <span> <i class="fas fa-clock-rotate-left marginRight8" aria-hidden="true" /> View Logs </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
        <template #content>
            <div v-if="['Course Owner'].includes(myCollaboratorRole.role)" class="marginBottom20 collapsingQuestion">
                <h3 class="btn notButton">
                    {{ collaboratorRequests.length }} {{ collaboratorRequests.length == 1 ? 'Staff is' : 'Staffs are' }} Awaiting Approval
                    <template v-if="collaboratorRequests.length == 0">
                        <i class="fw-normal marginLeft5"> - No course collaborator access requests are awaiting approval.</i>
                    </template>
                </h3>
                <div v-if="collaboratorRequests.length > 0" class="padding10" role="region">
                    <div class="table-responsive overflowAuto">
                        <table class="table">
                            <caption>
                                List of staffs awaiting approval
                            </caption>
                            <thead>
                                <tr class="blueHeader">
                                    <th>Name</th>
                                    <th class="minWidth300px width300px maxWidth300px">Course Collaborator Role Request As</th>
                                    <th class="minWidth300px width300px maxWidth300px">Requested On</th>
                                    <th class="minWidth360px width360px maxWidth360px">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="collaborator in collaboratorRequests" :key="collaborator.id">
                                    <td>{{ collaborator.displayName }}</td>
                                    <td>
                                        <span
                                            v-if="collaborator.role"
                                            class="tableStatusTag whiteSpaceNowrap dynamic-status"
                                            :style="{ '--main-color': $ability.relevantRuleFor('get_color', collaborator.role)?.conditions?.color }"
                                        >
                                            {{ collaborator.role }}
                                        </span>
                                        <template v-else> - </template>
                                    </td>
                                    <td>
                                        {{ convertToReadableDate(collaborator.requestedAt, 'DD MMM YYYY, h:mm a').date }}
                                        ({{ convertToReadableDate(collaborator.requestedAt, 'DD MMM YYYY, h:mm a').current_timezone }})
                                    </td>
                                    <td>
                                        <div class="flex gap10">
                                            <button class="btn btn-outline-danger flexInherit margin0" @click.prevent="denyCollaborator(collaborator)">
                                                <span class="material-symbols-outlined filled marginRight8" aria-hidden="true"> visibility_lock </span>
                                                Reject Access
                                            </button>
                                            <button class="btn btn-success flexInherit margin0" @click.prevent="approveCollaborator(collaborator)">
                                                <span class="material-symbols-outlined filled marginRight8" aria-hidden="true"> visibility </span>
                                                Grant Access
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div v-if="['Course Owner', 'Course Admin'].includes(myCollaboratorRole.role)" class="flex gap10 align-items marginBottom20">
                <label
                    v-tooltip="{ content: !selectedRole.id && !course.autoJoinRole.id ? 'Choose Course Collaborator Role first' : '', trigger: 'hover' }"
                    class="form-switch"
                >
                    <input
                        id="allowAutoJoinAsCollaborator"
                        v-tooltip="{ content: !selectedRole.id && !course.autoJoinRole.id ? 'Choose Course Collaborator Role first' : '', trigger: 'focus' }"
                        :checked="!!course.autoJoinRole.id"
                        :class="{ 'disabled hasTooltip': !selectedRole.id && !course.autoJoinRole.id }"
                        type="checkbox"
                        :aria-label="'Allow any instructor in my organisation to join as'"
                        @click.prevent="autoJoinEnabled"
                    />
                    <span class="slider round" />
                </label>
                <label for="allowAutoJoinAsCollaborator"> Allow any instructor in my organisation to join as {{ course.autoJoinRole.name }} </label>
                <button class="btn btn-outline-primary flexInherit margin0" @click.prevent="$refs['chooseCollaboratorRoleComponent'].select(course)">
                    <template v-if="course.autoJoinRole.id"> Change Course Collaborator Role </template>
                    <template v-else> Choose Course Collaborator Role </template>
                </button>
            </div>

            <div class="overflowXinherit marginBottom20">
                <div class="table-responsive">
                    <table class="table">
                        <caption>
                            List of Collaborators in Course
                            {{
                                course.name
                            }}
                        </caption>
                        <thead>
                            <tr class="blueHeader">
                                <th class="minWidth90px width90px maxWidth90px" scope="col">Actions</th>
                                <th>Name</th>
                                <th class="minWidth205px width205px maxWidth205px" scope="col">Course Collaborator Role</th>
                                <th class="minWidth300px width300px maxWidth300px" scope="col">Date joined</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="collaborator in courseCollaborators">
                                <td class="py-9px">
                                    <div class="dropup">
                                        <button
                                            :id="`viewDropdownMenuButton-${collaborator.id}`"
                                            v-tooltip="
                                                getMenuItemCount(collaborator) == 0 ? 'No Actions Available' : 'More actions for ' + collaborator.displayName
                                            "
                                            class="btn btn-outline-default icon-only margin0"
                                            :class="{ 'disabled hasToolTip': getMenuItemCount(collaborator) == 0 }"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :aria-label="
                                                getMenuItemCount(collaborator) == 0 ? 'No Actions Available' : 'More actions for ' + collaborator.displayName
                                            "
                                        >
                                            <i class="fa-solid fa-ellipsis-vertical"></i>
                                        </button>
                                        <div
                                            :id="`dropdown-${collaborator.id}`"
                                            class="dropdown-menu dropdown-menu-left dropdown-menu-new"
                                            :aria-labelledby="`viewDropdownMenuButton-${collaborator.id}`"
                                        >
                                            <ul :key="collaborator.id" role="menu">
                                                <li v-if="canManageCollaborator(collaborator)" role="menuitem">
                                                    <a
                                                        class="primary-state"
                                                        href="#"
                                                        @click.prevent="$refs['switchCourseCollaboratorRoleComponent'].switchRole(course, collaborator)"
                                                    >
                                                        <span>
                                                            <span class="material-symbols-outlined filled marginRight8" aria-hidden="true">
                                                                switch_account </span
                                                            >Switch Course Collaborator Role
                                                        </span>
                                                    </a>
                                                </li>
                                                <li v-if="canTransferOwnership(collaborator)" role="menuitem">
                                                    <a
                                                        class="primary-state"
                                                        href="#"
                                                        @click.prevent="
                                                            $refs['transferCourseOwnershipComponent'].transfer(
                                                                course,
                                                                collaborator,
                                                                courseCollaborators.find((c) => c.role === 'Course Owner')?.displayName || ''
                                                            )
                                                        "
                                                    >
                                                        <span>
                                                            <span class="material-symbols-outlined filled marginRight8" aria-hidden="true"> sync_alt </span
                                                            >Transfer Course Ownership
                                                        </span>
                                                    </a>
                                                </li>
                                                <hr v-if="canManageCollaborator(collaborator)" />
                                                <li v-if="canManageCollaborator(collaborator)" role="menuitem">
                                                    <a
                                                        class="danger-state"
                                                        href="#"
                                                        @click.prevent="$refs['removeCollaboratorComponent'].remove(course, collaborator)"
                                                    >
                                                        <span>
                                                            <span class="material-symbols-outlined filled marginRight8" aria-hidden="true"> group_remove </span
                                                            >Remove Collaborator
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    {{ collaborator.displayName }}
                                    <span v-if="collaborator.userUuid == auth.user().uuid" class="warningColor"> (Me) </span>
                                    <br />
                                    <span class="fontGrey fs-16px">
                                        {{ collaborator.email }}
                                    </span>
                                </td>
                                <td>
                                    <span
                                        v-if="collaborator.role"
                                        class="tableStatusTag whiteSpaceNowrap dynamic-status"
                                        :style="{ '--main-color': $ability.relevantRuleFor('get_color', collaborator.role)?.conditions?.color }"
                                    >
                                        {{ collaborator.role }}
                                    </span>
                                    <template v-else> - </template>
                                </td>
                                <td v-dompurify-html="parseCreatedAt(collaborator.createdAt)" />
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="collapsingQuestion">
                <button
                    class="btn"
                    :data-target="'#view-course-collaborator-roles-matrix'"
                    data-toggle="collapse"
                    aria-expanded="false"
                    :aria-controls="'view-course-collaborator-roles-matrix'"
                >
                    View Course Collaborator Roles and Permissions Matrix Reference
                </button>
                <div :id="'view-course-collaborator-roles-matrix'" class="collapse padding20 position-relative">
                    <CourseCollaboratorRolesMatrix />
                </div>
            </div>

            <LeaveCollaboratorComponent ref="leaveCollaboratorComponent" />
            <ChooseCollaboratorRoleComponent ref="chooseCollaboratorRoleComponent" @select-role="getSelectedRole" />
            <SwitchCourseCollaboratorRoleComponent ref="switchCourseCollaboratorRoleComponent" @switch-role="fetchCourseCollaborator" />
            <TransferCourseOwnershipComponent ref="transferCourseOwnershipComponent" @transfer-ownership="fetchCourseCollaborator" />
            <RemoveCollaboratorComponent ref="removeCollaboratorComponent" @collaborator-removed="fetchCourseCollaborator" />
            <ViewLogsComponent ref="viewLogsComponent" />
        </template>
    </kr-panel>
</template>
<script setup>
import KrAuth from '../../../../components/auth/auth';
import { ref, onMounted, getCurrentInstance, onUnmounted } from 'vue';
import { notify } from '@kyvg/vue3-notification';
import LeaveCollaboratorComponent from './actions/leaveCollaborator.vue';
import ChooseCollaboratorRoleComponent from './actions/ChooseCollaboratorRole.vue';
import SwitchCourseCollaboratorRoleComponent from './actions/switchCourseCollaboratorRole.vue';
import TransferCourseOwnershipComponent from './actions/transferCourseOwnership.vue';
import RemoveCollaboratorComponent from './actions/removeCollaborator.vue';
import { globalMixins } from '../../../../mixins/global';
import CourseCollaboratorRolesMatrix from '../../settings/form-partials/partial.course-collaborator-roles.vue';
import ViewLogsComponent from './actions/logs.vue';

const { proxy } = getCurrentInstance();
const { convertToReadableDate } = globalMixins.methods;

const emit = defineEmits(['course-updated']);

const auth = new KrAuth();
const props = defineProps({
    course: {
        type: Object,
        required: true,
    },
});

const component_done_loading = ref(false);
const courseCollaborators = ref([]);
const collaboratorRequests = ref([]);
const selectedRole = ref({ id: '', name: '' });
const myCollaboratorRole = ref('');
const unassignedStaffs = ref([]);

const fetchCollaboratorRequests = () => {
    axios.get(`courses/${props.course.uuid}/collaborator-requests`).then((response) => {
        collaboratorRequests.value = response.data.data;
    });
};

const fetchCourseCollaborator = () => {
    axios.get(`courses/${props.course.uuid}/collaborators`).then((response) => {
        courseCollaborators.value = response.data.data;
        unassignedStaffs.value = response.data.meta.teachersNotInTheCourse;
        myCollaboratorRole.value = courseCollaborators.value.find((collaborator) => collaborator.userUuid == auth.user().uuid);
    });
};

const refreshData = () => {
    fetchCollaboratorRequests();
    fetchCourseCollaborator();
};

const approveCollaborator = (collaborator) => {
    axios
        .post(`courses/${props.course.uuid}/collaborator-requests/${collaborator.id}/approve`)
        .then((response) => {
            refreshData();
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Collaborator access approved',
            });
        })
        .catch((error) => {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong, please try again later',
            });
        });
};

const denyCollaborator = (collaborator) => {
    axios
        .post(`courses/${props.course.uuid}/collaborator-requests/${collaborator.id}/deny`)
        .then((response) => {
            refreshData();
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Collaborator access denied',
            });
            refreshData();
        })
        .catch((error) => {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong, please try again later',
            });
        });
};

const downloadCollaborators = () => {
    let d = moment().format('YYYY[_]MM[_]DD');
    axios({
        url: `teachers/courses/${props.course.uuid}/export`,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Collaborators_' + d + '.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
};

const canManageCollaborator = (collaborator) => {
    return (
        collaborator.role != 'Course Owner' &&
        ['Course Owner', 'Course Admin'].includes(myCollaboratorRole.value.role) &&
        auth.user().uuid != collaborator.userUuid
    );
};

const canTransferOwnership = (collaborator) => {
    if (auth.user().role == 'Super Admin') {
        return collaborator.role != 'Course Owner' && myCollaboratorRole.value.role == 'Course Admin' && collaborator.userUuid != auth.user().uuid;
    } else {
        return collaborator.role != 'Course Owner' && myCollaboratorRole.value.role == 'Course Owner';
    }

    return false;
};

const getMenuItemCount = (collaborator) => {
    let count = 0;

    if (canManageCollaborator(collaborator)) {
        count += 2;
    }

    if (canTransferOwnership(collaborator)) {
        count += 1;
    }

    return count;
};

const autoJoinEnabled = () => {
    if (props.course.autoJoinRole.id) {
        axios
            .patch(`courses/${props.course.uuid}/collaborators/settings/autojoin`, {
                roleId: selectedRole.value.id && selectedRole.value.id !== props.course.autoJoinRole.id ? selectedRole.value.id : null,
            })
            .then(() => {
                if (selectedRole.value.id && selectedRole.value.id !== props.course.autoJoinRole.id) {
                    props.course.autoJoinRole.id = selectedRole.value.id;
                    props.course.autoJoinRole.name = selectedRole.value.name;
                } else {
                    props.course.autoJoinRole.id = null;
                    props.course.autoJoinRole.name = '';
                }

                selectedRole.value = { id: '', name: '' };
                notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: selectedRole.value.id && selectedRole.value.id !== props.course.autoJoinRole.id ? 'Auto join role updated' : 'Auto join disabled',
                });
                emit('course-updated');
            });
        return;
    }

    if (!selectedRole.value.id) {
        $refs.chooseCollaboratorRoleComponent.select(props.course);
        return;
    }

    axios
        .patch(`courses/${props.course.uuid}/collaborators/settings/autojoin`, {
            roleId: selectedRole.value.id,
        })
        .then(() => {
            props.course.autoJoinRole.id = selectedRole.value.id;
            props.course.autoJoinRole.name = selectedRole.value.name;
            selectedRole.value = { id: '', name: '' };
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Auto join enabled',
            });
            emit('course-updated');
        });
};

const getSelectedRole = (role) => {
    selectedRole.value = role;
    autoJoinEnabled();
};

const parseCreatedAt = (date) => {
    if (!date) return '';

    const noteTimeUTC = moment.utc(date);

    const relativeTime = noteTimeUTC.fromNow();
    return `<p>${convertToReadableDate(date, 'DD MMM YYYY, hh:mm a').date} (${
        convertToReadableDate(date, 'DD MMM YYYY, hh:mm a').current_timezone
    })</p><small class="fontGrey"><em>${relativeTime}</em></small>`;
};

const processEchoListeners = () => {
    let h1 = (e) => {
        fetchCollaboratorRequests();
        fetchCourseCollaborator();
    };
    let c1 = window.Echo.private(`course.${props.course.uuid}.teacher`).listen('CollaboratorsUpdated', h1);
    proxy.echoChannels.push({
        channel: c1,
        event: 'CollaboratorsUpdated',
        handler: h1,
    });
};

onMounted(() => {
    processEchoListeners();
    fetchCollaboratorRequests();
    fetchCourseCollaborator();
    component_done_loading.value = true;
});

onUnmounted(() => {
    proxy.removeEchoListener('CollaboratorsUpdated');
});
</script>
