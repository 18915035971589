<template>
    <div
        ref="modal"
        class="modal danger-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="removeSuperAdminModal-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="removeSuperAdminModal-title" class="modal-title">Remove Super Admin role</h2>
                </div>
                <div class="modal-body textAlignCenter">
                    <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                    <h2 class="marginTop40">Remove Role From</h2>
                    <h3 class="fw-normal">{{ values.displayName }}</h3>

                    <p class="marginTop20">Removing their super admin role</p>
                    <p>will revoke their access to the course</p>
                    <p>unless, they are also invited as a teacher</p>

                    <p class="marginTop20">
                        <b>Do you wish to continue?</b>
                    </p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                    <button class="btn btn-danger" @click.prevent="submit"><i class="fa fa-trash-o marginRight8" aria-hidden="true" />Yes, Remove Role</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';
import { notify } from '@kyvg/vue3-notification';
import { useRoute } from 'vue-router';

const route = useRoute();

const emit = defineEmits(['emit-suspend']);
const modal = ref(null);
const values = ref({});

const remove = (v = {}) => {
    values.value = v;
    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const submit = () => {
    axios
        .delete(`/accounts/${route.params.id}/superadmin`, {
            data: { superadminUuid: values.value.uuid },
        })
        .then(function (response) {
            closeModal();
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Super Admin role has been removed',
            });
            Events.fire('superadmins_listing_refresh');
        })
        .catch(function () {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong, please try again later ',
            });
        });
};

defineExpose({ remove });
</script>
